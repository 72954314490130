<template>
  <div id="client_card" class="d-flex justify-content-center align-items-center mt-lg-0 mt-0">
    <div class="main_card px-2">
      <div class="pt-1 row">
<!--        <div class="col-12">-->
<!--          <div class="icon1 mb-2">-->
<!--&lt;!&ndash;            <img :src="baseUrl + card.icon" :alt="card.title">&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
        <div class="col-12 title-margin">
          <div class="title1">
            <h1> {{card.title}} </h1>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="body1 px-1">
            <p> {{card.description}} </p>
          </div>
        </div>
        <div class="col-12 d-flex align-items-end justify-content-center">
<!--          <router-link :to="'/ServicesDetails/' + card.id">-->
<!--            <div class="read_more mt-3">{{$t('read_more')}}</div>-->
<!--          </router-link>-->
          <div class="read_more mt-3" v-on:click="openModal">{{$t('read_more')}}</div>
<!--          <button type="button" @click="open">Open Modal</button>-->
        </div>
      </div>
    </div>
    <div class="border_card"></div>
    <div v-if="loadModal">
      <modal name="hello" :disable-backdrop="true">
        <div class="basic-modal">
          <div class="title1">
            <h1> {{card.title}} </h1>
          </div>
          <div class="">
            <div class="body1 px-1">
              <p> {{card.description}} </p>
            </div>
          </div>
          <span class="close-modal" v-on:click="closeModal"><img src="../../../assets/icons/close button.svg"></span>
<!--          <button class="button" type="button" v-on:click="closeModal">Close Modal</button>-->
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import Constants from '../../../Plugins/Constants'
import $ from 'jquery'

export default {
  name: 'ClientCard',
  data () {
    return {
      baseUrl: Constants.baseUrl,
      loadModal: false
    }
  },
  props: {
    card: {
      title: String,
      description: String,
      icon: String,
      images: Array
    }
  },
  methods: {
    openModal () {
      this.$modal.push('hello')
      this.loadModal = !this.loadModal
    },
    closeModal () {
      this.$modal.pop()
      this.loadModal = !this.loadModal
    }
  },
  mounted () {
    // console.log('Yazan')
    // $('.basic-modal').clickOutsideThisElement(function() {
    //   this.loadModal = !this.loadModal
    //   // Hide the menus
    // })
    if (localStorage.getItem('lang') === 'ar') {
      $('#client_card .body1 p').css('direction', 'rtl')
      // $('.modal-content').addClass('ar')
      // $('.hello').modal.css('direction', 'rtl')
      // $('.basic-modal .body1 p').css('direction', 'rtl')
      // $('.modal-content').addClass('ar')
      // $('.close-modal').css('left', '15px')
    } else {
      $('#client_card .body1 p').css('direction', 'ltr')
      // $('.modal-content').addClass('en')
      // $('.hello').modal.addClass('en')
      // $('.modal-content').addClass('en')
      // $('.hello').modal.css('direction', 'ltr')
      // $('.close-modal').css('right', '15px')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
/* width */
.modal-content .body1 p::-webkit-scrollbar {
  width: 8px;
  //scroll-padding: 20px;
}

/* Track */
.modal-content .body1 p::-webkit-scrollbar-track {
  //background: #f1f1f1;
}

/* Handle */
.modal-content .body1 p::-webkit-scrollbar-thumb {
  background: rgba(0, 0,0, 0.2);
  border-radius: 20px;
}

/* Handle on hover */
.modal-content .body1 p::-webkit-scrollbar-thumb:hover {
  //background: #555;
}
.modal-content {
  position: fixed!important;
  top: 30%;
  //width: 40%;
  //height: 45%;
  width: 600px;
  height: 300px;
  //padding: 20px!important;
  padding: 0px!important;
  background: $form_background;
  box-shadow: 5px 5px 3px rgba(255,255,255,0.3), -4px -4px 3px rgba(255,255,255,0.4);
  border-radius: 10px;
  filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -webkit-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -moz-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  -o-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.3));
  //scroll-padding: 50px;
}
//.modal-content:after {
//  content: "";
//  display: block;
//  height: 50px;
//  width: 100%;
//}
.ar {
  direction: rtl!important;
}
.en {
  direction: ltr!important;
}
.basic-modal {
  //position: relative;
}
.close-modal {
  position: fixed!important;
  right: 17px;
  top: 17px;
  cursor: pointer;
}
.close-modal img {
  width: 25.66px;
  height: 13.08px;
}
//.modal-content.ar {
//  direction: rtl!important;
//}
//.modal-content.en {
//  direction: ltr!important;
//}
#client_card .icon1 {
  //margin: 7vh 0 1.5vh 0;
  margin: 2rem 0 0.3rem 0;
}
#client_card .title-margin {
  margin-top: 3rem;
}
#client_card .title1 {
  //margin-bottom: 2.5vh;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.modal-content .title1 {
  margin-top: 2rem;
}
.modal-content .title1 {
  text-align: center;
}
#client_card .icon1 img {
  //width: 4.9vmin;
  width: 32.9px;
  height: 32.99px;
  //height: 4.9vmin;
}
#client_card .title1 h1, .modal-content .title1 h1 {
  color: $definitionCardBlue;
  font-family: Messiri-Bold;
  font-size: 16.5px;
}
#client_card .body1 p {
  font-size: 15.52px;
  color: $definitionblack;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  //text-align: justify;
  height: 118px;
  overflow-wrap: anywhere;
}
.modal-content .body1 p {
  font-size: 15.52px;
  font-family: Messiri-Regular;
  color: $definitionblack;
  //overflow-wrap: anywhere;
  text-align: center;
  height: 200px;
  //background: red;
  overflow-y: scroll!important;
  padding: 0 3.5px;
  overflow-wrap: anywhere;
}
#client_card {
  filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
  -webkit-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
  -moz-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
  -o-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
}
#client_card .main_card {
  clip-path: polygon(50% 0, 100% 20%, 100% 81%, 50% 100%, 0 81%, 0 20%);
  box-shadow: inset 5px 5px 10px rgba(255,255,255,0.25), inset -5px -5px 10px rgba(255,255,255,0.25);
  //width: 237.78px;
  //height: 247.01px;
  width: 332.2704px;
  height: 356.6244px;
  background: $lightGray;
  z-index: 100;
  position: absolute;
}
#client_card .border_card {
  //width: 199.84px;
  //height: 207.60px;
  width: 279.2658px;
  height: 290.103px;
  bottom: -43.05px;
  z-index: 90;
  position: relative;
  background: $definitionblue;
  clip-path: polygon(50% 0, 100% 20%, 100% 81%, 50% 100%, 0 81%, 0 20%);
}
#client_card .read_more {
  cursor: pointer;
  color: $orangecarousel;
  display: inline-block;
  border-bottom: 1.5px solid;
  //position: absolute;
  //bottom: 5vh;
  //left:50%;
  //margin-left:-20.635px;
  line-height: 0.9;
  //text-align: center;
  //float: right;
  //margin-left: 23.27px;
  font-size: 11.88px;
}
@media screen  and (max-width: 480px) {
  //#service_card .main_card {
  //  width: 53.866vmin;
  //  height: 55.957vmin;
  //}
  //#service_card .border_card {
  //  width: 47.866vmin;
  //  height: 49.957vmin;
  //  bottom: -4.4vmin;
  //}
  #client_card .main_card {
    width: 273.1674px;
    height: 293.8254px;
  }
  #client_card .border_card {
    //width: 292.94px!important;
    //height: 18.48px;
    width: 229.5876px;
    height: 238.4976px;
    bottom: -35.04px;
  }
  #client_card .title-margin, .modal-content .title-margin {
    margin-top: 1rem;
  }
}
@media screen  and (max-width: 480px) {
  .modal-content {
    //width: 80%;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  //#service_card .icon1 img {
  //  width: 5.9vmin;
  //  height: 5.9vmin;
  //}
  //#service_card .title1 h1 {
  //  font-size: 3.5vmin;
  //}
  //#service_card .body1 p {
  //  font-size: 3vmin;
  //}
  //#service_card .icon1 {
  //  margin: 3.5vh 0 1.3vh 0;
  //}
  //#service_card .title1 {
  //  margin-bottom: 1.7vh;
  //}
  //#service_card .read_more {
  //  bottom: 5.5vh;
  //  font-size: 2.5vmin;
  //}
  .modal-content {
    //width: 70%!important;
  }
}
@media screen and (max-width: 999px) and (min-width: 768px) {
  //#service_card .icon1 img {
  //  width: 5.1vmin;
  //  height: 5.1vmin;
  //}
  //#service_card .title1 h1 {
  //  font-size: 2.7vmin;
  //}
  //#service_card .body1 p {
  //  font-size: 2.2vmin;
  //}
  //#service_card .icon1 {
  //  margin: 10vh 0 3vh 0;
  //}
  //#service_card .title1 {
  //  margin-bottom: 3vh;
  //}
  //#service_card .read_more {
  //  bottom: 5.5vh;
  //  font-size: 2vmin;
  //}
  .modal-content {
    //width: 60%!important;
  }
}
@media screen and (max-width: 768px) and (min-width: 480px){
  .modal-content {
    width: 400px;
    height: 300px;
  }
  .modal-content .body1 p {
    height: 210px;
  }
}
@media screen and (max-width: 480px) {
  .modal-content {
    width: 300px;
    height: 400px;
  }
  .modal-content .body1 p {
    height: 320px;
  }
}
</style>
