<template>
  <section id="dama_by_numbers">
    <div class="row">
      <div class="col-12 main pt-5 pb-1">
        <h1>{{$t('counter.main_title')}}</h1>
      </div>
      <div class="col-12 second pb-2">
        <p>{{$t('counter.second_title')}}</p>
      </div>
    </div>
    <div class="numbers pb-5">
      <div class="row px-2">
        <div class="col-3">
          <div class="title_number mt-3">
            <span v-if="statistic[0].value != 0" class="num counter" data-speed="7000">{{ statistic[0].value}}</span>
            <br>
            <p v-if="statistic[0].value != 0" class="val">{{ statistic[0].title}}</p>
          </div>
        </div>
        <div class="col-3">
          <div class="title_number mt-3">
            <span v-if="statistic[1].value != 0" class="num counter" data-speed="7000">{{ statistic[1].value}}</span>
            <br>
            <p class="val" v-if="statistic[1].value != 0">
              {{ statistic[1].title}}
            </p>
          </div>
        </div>
        <div class="col-3">
          <div class="title_number mt-3">
            <span v-if="statistic[2].value != 0" class="num counter" data-speed="7000">{{ statistic[2].value}}</span>
            <br>
            <p v-if="statistic[2].value != 0" class="val">{{ statistic[2].title}}</p>
          </div>
        </div>
        <div class="col-3">
          <div class="title_number mt-3">
            <span v-if="statistic[3].value != 0" class="num counter" data-speed="7000">{{ statistic[3].value}}</span>
            <br>
            <p v-if="statistic[3].value != 0" class="val">{{ statistic[3].title}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import $ from 'jquery'
// import StatisticsRepository from '../../Repositories/StatisticsRepository'

export default {
  name: 'DamaByNumbers',
  props: ['cards'],
  data () {
    return {
      name: 'num',
      count: false,
      // StatisticsRepository: new StatisticsRepository(),
      statistic: [
        {
          title: '',
          value: ''
        },
        {
          title: '',
          value: ''
        },
        {
          title: '',
          value: ''
        },
        {
          title: '',
          value: ''
        }
      ]
    }
  },
  methods: {
    counter () {
      $('.counter').each(function () {
        $(this).prop('Counter', 0).animate({
          Counter: $(this).text()
        }, {
          duration: 4000,
          easing: 'swing',
          step: function (now) {
            $(this).text(Math.ceil(now))
          }
        })
      })
    },
    handleScroll () {
      const el = document.getElementById('dama_by_numbers')
      // console.log(window.scrollY);
      // console.log('element ', el.offsetTop);
      if ((el.offsetTop - window.scrollY) < 550 && !this.count) {
        this.counter()
        this.count = true
      }
    },
    get_statistics () {
      // const response = await this.StatisticsRepository.get()
      // this.cards = response.data.body
      this.statistic = this.cards
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#dama_by_numbers').css('direction', 'rtl')
    } else {
      $('#dama_by_numbers').css('direction', 'ltr')
    }
  },
  watch: {
    cards (val) {
      this.statistic = val
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/scss/main";
* {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -o-box-sizing:border-box;
  box-sizing:border-box;
  //direction: rtl;
}
#dama_by_numbers {
  min-height: 260.9px;
}
#dama_by_numbers .main h1 {
  color: $buttonorange;
  font-size: 29.7px!important;
  border-bottom: 1.5px solid;
  font-family: Messiri-Bold;
  display: inline-block;
  line-height: 0.9;
}
#dama_by_numbers .second p {
  color: $primarywhite;
  font-size: 14.52px;
  font-family: Messiri-Regular;
}
#dama_by_numbers {
  background-image: url('../../assets/images/counter.png');
  background-size: cover;
  overflow: hidden;
}
.title_number .num{
  color: $buttonorange;
  font-size: 49.5px;
  //margin-left: 5px;
  font-weight: bold;
  font-family: Messiri-Bold;
}
.title_number .val{
  color: $primarywhite;
  font-size: 13.2px;
  font-family: Messiri-Regular;
  position: relative;
  top: -1vmin;
}
@media screen and (max-width: 768px) {
  //#dama_by_numbers .main h1 {
  //  font-size: 4.9vmin!important;
  //}
  //#dama_by_numbers .second p {
  //  font-size: 3.5vmin!important;
  //}
  //.title_number .val {
  //  font-size: 3vmin!important;
  //}
  //.title_number .num {
  //  font-size: 8vmin!important;
  //}
}
@media screen  and (max-width: 992px) and (min-width: 768px) {
  //#dama_by_numbers .main h1 {
  //  font-size: 4.7vmin!important;
  //}
  //#dama_by_numbers .second p {
  //  font-size: 2.7vmin!important;
  //}
  //.title_number .val {
  //  font-size: 2.2vmin!important;
  //}
}
@media screen and (max-width: 480px) {
  .title_number .num{
    font-size: 39.7px!important;
  }
}

</style>
