<template>
    <div id="intro" v-on:click="closeNav">
      <carousel :cards="cards" :contactInfo="contactInfo"/>
    </div>
</template>

<script>
import Carousel from '../components/Intro/Carousel'
export default {
  name: 'Intro',
  components: { Carousel },
  props: {
    cards: [],
    contactInfo: {}
  },
  data () {
    return {
      action: false
    }
  },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    }
  },
  created () {
    // console.log('cards Intro', this.cards)
  },
  watch: {
    contactInfo (val) {
      this.contactInfo = val
    },
    cards (val) {
      this.cards = val
    }
  }
}
</script>

<style scoped>
#intro {
}

</style>
