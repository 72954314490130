<template>
  <div id="news_card" class="mt-lg-0 mt-0 d-flex align-items-center justify-content-center">
    <!--    <div class="main_card" :class="'slide'+counter"></div>-->
    <div class="border-box">
      <div class="main_card" :style="{ 'background': 'url( ' + baseUrl + card.images[0].image + ') no-repeat center center'}" role="img" :aria-label="card.title"></div>
      <div class="text_card d-flex align-items-end justify-content-center">
        <div class="">
          <div class="title1">
            <h1> {{card.title}} </h1>
          </div>
          <p class="body1"> {{card.created_at}} </p> <br/>
          <router-link :to="'/NewsDetails/' + card.id">
            <div class="read_more"> {{$t('read_more')}} </div>
          </router-link>
        </div>
      </div>
      <div class="border_card"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Constants from '../../../Plugins/Constants'
import NewsRepository from '../../../Repositories/NewsRepository'

export default {
  name: 'NewsCard',
  data () {
    return {
      // results: [],
      // numPages: 0,
      // page: 1,
      // per_page: 4,
      baseUrl: Constants.baseUrl,
      NewsRepo: new NewsRepository()
      // card: []
    }
  },
  props: {
    // counter: Number
    card: {
      id: Number,
      title: String,
      description: String,
      created_at: String
    }
  },
  methods: {
    // async getPage (slideIndex) {
    //   // console.log('our carousel is ready', slideIndex)
    //   const x = await this.NewsRepo.getPage(this.page)
    //   // console.log('our carousel is ready', x)
    //   this.results = x.content
    //   // this.page ===+ 1
    //   // console.log('results-newsss', this.results)
    //   // console.log('results-images', '+ this.baseUrl + results[1].images[1].image +')
    // }
    // async get_single_news (id) {
    //   // console.log('kk', this.section)
    //   const response = await this.NewsRepo.getSigleNewsById(id)
    //   this.card = response.data.body
    //   // console.log('tata', this.positions)
    // }
  },
  created () {
    // this.getPage()
    // this.get_single_news(this.counter)
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#news_card .text_card').css('text-align', 'right')
    } else {
      $('#news_card .text_card').css('text-align', 'left')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
#news_card .border-box {
  position: relative;
}
#news_card .title1 h1 {
  font-family: Messiri-Bold;
  text-decoration: underline;
  font-size: 23.1px;
  display: inline-block;
  margin-bottom: 1rem;
}
#news_card .body1 {
  font-size: 10.56px;
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 1.5rem;
}
#news_card .read_more {
  font-family: Messiri-Bold;
  color: $buttonorange;
  font-size: 16.5px;
  text-decoration: underline;
  display: none;
  margin-bottom: 0.5rem;
  text-underline-position: under!important;
}
#news_card .main_card {
  width: 382.14px;
  height: 334.62px;
  z-index: 100;
  position: relative;
  background-size: cover!important;
}
#news_card .border_card {
  //width: 45.9vmin;
  width: 382.14px;
  height: 18.48px;
  //border-top: 1px solid #707070;
  bottom: 25.48px;
  z-index: 110;
  position: absolute;
  background: $definitionblue;
  transition: all .2s ease-out;
}
#news_card .text_card {
  z-index: 120;
  width: 382.14px;
  height: 334.62px;
  position: absolute;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  bottom: 40.48px;
}
#news_card .border-box:hover .border_card {
  height: 184.4px;
  background-color: rgba(46, 49, 146, 0.65) !important;
  backdrop-filter: blur(8px) opacity(1);
  -webkit-backdrop-filter: blur(8px) opacity(1);
  -moz-backdrop-filter: blur(8px) opacity(1);
  -o-backdrop-filter: blur(8px) opacity(1);
  transition: all .3s ease-out;
}
/* Safari 10.1+ */
//@media not all and (min-resolution:.001dpcm)
//{ @supports (-webkit-appearance:none) {
//  #news_card .main_card {
//    width: 302.94px!important;
//    height: 295.02px!important;
//  }
//  #news_card .border_card {
//    width: 302.94px!important;
//    //height: 18.48px;
//  }
//  #news_card .text_card {
//    width: 302.94px!important;
//    height: 295.02px!important;
//  }
//  #news_card .border-box:hover .border_card {
//    //height: 184.4px;
//  }
//}}
#news_card .border-box:hover .read_more {
  display: inline-block;
}
@media screen  and (max-width: 999px) {
  //#news_card .main_card {
  //  width: 75.738vmin;
  //  height: 69.231vmin;
  //}
  //#news_card .border_card {
  //  width: 75.738vmin;
  //  //height: 69.231vmin;
  //}
}
@media screen and (max-width: 768px) {
  // #news_card .title1 h1 {
  //  font-size: 4.5vmin;
  //   margin-bottom: 1vh;
  // }
  //#news_card .body1 {
  //  font-size: 2.6vmin;
  //  margin-bottom: 3vh;
  //}
  //#news_card .read_more {
  //  font-size: 3.5vmin;
  //  margin-bottom: 1.5vh;
  //}
  //#news_card .main_card, #news_card .text_card {
  //  width: 61.9vmin;
  //  height: 60.7vmin;
  //}
  //#news_card .border_card {
  //  width: 61.9vmin;
  //}
  //#news_card:hover .border_card {
  //  height: 33vmin;
  //}
}
@media screen and (max-width: 999px) and (min-width: 768px) {
}
@media screen and (max-width: 480px) {
#news_card .main_card {
  width: 292.94px!important;
  height: 295.02px!important;
}
#news_card .border_card {
  width: 292.94px!important;
  //height: 18.48px;
}
#news_card .text_card {
  width: 292.94px!important;
  height: 295.02px!important;
}
#news_card .border-box:hover .border_card {
  //height: 184.4px;
}
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  #news_card .main_card {
    width: 331.94px!important;
    height: 295.02px!important;
  }
  #news_card .border_card {
    width: 331.94px!important;
    //height: 18.48px;
  }
  #news_card .text_card {
    width: 331.94px!important;
    height: 295.02px!important;
  }
  #news_card .border-box:hover .border_card {
    //height: 184.4px;
  }
}
</style>
