import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import 'bootstrap'
// import 'jquery/dist/jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import VueThinModal from 'vue-thin-modal'
import 'vue-thin-modal/dist/vue-thin-modal.css'
import VeeValidate from 'vee-validate'
import VueI18n from 'vue-i18n'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VeeValidate)
Vue.use(VueThinModal)
Vue.use(VueI18n)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
