<template>
  <div id="contact_form" class="pt-md-4 pt-5 pb-4 mb-md-0 mb-2">
    <form action="" class="flex-row-reverse" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-12 title">
          <h1>{{$t('contact_form.title')}}</h1>
        </div>
        <div class="col-12 description pb-1">
          <p>{{$t('contact_form.description')}}</p>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <input v-validate="'required|max:100'" class="form-control py-2" type="text" id="fname" name="firstname" v-bind:placeholder="$t('contact_form.first_name')+'*'" v-model="first_name" :class="this.errors.has('firstname') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors" class="error_message">{{ errors.first('firstname')? $t('required') :'' }}</span>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <input v-validate="'required|max:100'" class="form-control py-2" type="text" id="lname" name="lastname" v-bind:placeholder="$t('contact_form.last_name')+'*'" v-model="last_name" :class="this.errors.has('lastname') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors" class="error_message">{{ errors.first('lastname')? $t('required') :'' }}</span>

        </div>
        <div class="col-md-6 col-12 mb-3">
          <input v-validate="'required|email|max:255'" class="form-control py-2" type="text" id="email" name="email" v-bind:placeholder="$t('contact_form.email')+'*'" v-model="email" :class="this.errors.has('email') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors && this.errors.first('email') != '' && this.errors.first('email') != 'The email field must be a valid email'" class="error_message">{{ errors.first('email')? $t('required') :'' }}</span><span v-if="showErrors && this.errors.first('email') != '' && this.errors.first('email') == 'The email field must be a valid email' " class="error_message">{{ errors.first('email')? $t('email') :'' }}</span>
        </div>
        <div class="col-md-6 col-12 mb-3">
          <input v-validate="'numeric|min:4|max:15'" pattern="[0-9]*||[٠-٩]*" @keypress="preventNonNumericalInput($event)" class="form-control py-2" type="text" id="phone" name="phone" v-bind:placeholder="$t('contact_form.phone')" v-model="phone" :class="this.errors.has('phone') && this.showErrors ? 'red-border' : ''">
          <span v-if="showErrors" class="error_message">{{ errors.first('phone')? $t('phone') :'' }}</span>
        </div>
        <div class="col-12 mb-3">
          <textarea v-validate="'required|max:10000'" rows="6" class="form-control py-2" type="text" id="message" name="message" v-bind:placeholder="$t('contact_form.suggest')+'*'" v-model="message" :class="this.errors.has('message') && this.showErrors ? 'red-border' : ''"></textarea>
          <span v-if="showErrors" class="error_message">{{ errors.first('message')? $t('required') :'' }}</span>
        </div>
        <div class="col-12 suggest">
          <button v-if="!submitSuccess" type="submit" value="submit" class="py-2 px-4">
            <span class="send_button" v-if="!loading">
              {{$t('contact_form.send')}}<span>&nbsp;&nbsp;<img src="../../assets/icons/send_button.svg"></span>
            </span>
            <span class="loading" v-if="loading">
              <span class="spinner-border text-light loader_style text-center" role="status">
              </span>
            </span>
          </button>
          <div class="success" v-if="submitSuccess">
            <!--            <font-awesome-icon class="icon" icon="check"/>-->
            <div class="success_message"> {{$t('submit_success')}} </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from 'jquery'
import FeedbackRepository from '../../Repositories/FeedbackRepository'

export default {
  name: 'ContactForm',
  data () {
    return {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      message: '',
      showErrors: false,
      loading: false,
      submitSuccess: false,
      FeedbackRepository: new FeedbackRepository()
    }
  },
  methods: {
    preventNonNumericalInput (e) {
      e = e || window.$event
      var charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which
      var charStr = String.fromCharCode(charCode)

      if (!charStr.match(/^[0-9]|[٠-٩]$/)) {
        e.preventDefault()
      }
    },
    async submitForm () {
      this.$validator.validate()
      this.showErrors = true
      if (!this.errors.has('firstname') && this.first_name !== '' && !this.errors.has('lastname') && this.last_name !== '' && !this.errors.has('email') && this.email !== '' && !this.errors.has('message') && this.message !== '' && !this.errors.has('phone')) {
        this.loading = true
        const formData = new FormData()
        formData.append('first_name', this.first_name)
        formData.append('last_name', this.last_name)
        formData.append('phone', this.phone)
        formData.append('email', this.email)
        formData.append('message', this.message)
        const response = await this.FeedbackRepository.create(formData)
        // console.log('fatima', response)
        if (response.status === 200 || response.status === true) {
          this.loading = false
          this.submitSuccess = true
        } else {
          // this.savingSuccessful = true
        }
      }
    }
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#contact_form .error_message').css('float', 'right!important')
      $('#contact_form').css('direction', 'rtl')
      $('#contact_form').css('padding-right', '3rem')
      $('#contact_form').css('padding-left', '1.5rem')
      $('#contact_form').css('margin-left', '1.5rem')
      $('#contact_form .title, #contact_form .description, #contact_form .suggest').css('text-align', 'right')
    } else {
      $('#contact_form .error_message').css('float', 'left!important')
      $('#contact_form').css('direction', 'ltr')
      $('#contact_form').css('padding-right', '1.5rem')
      $('#contact_form').css('padding-left', '3rem')
      $('#contact_form').css('margin-right', '1.5rem')
      $('#contact_form .title, #contact_form .description, #contact_form .suggest').css('text-align', 'left')
      $('#contact_form .suggest img').css('transform', 'rotate(270deg)')
    }
  }
}
</script>

<style lang="scss">
@import "src/scss/main";
#contact_form {
  text-underline-position: under!important;
  background-color: rgba(255,255,255,0.3) !important;
  backdrop-filter: blur(10px) opacity(1) brightness(1);
  -webkit-backdrop-filter: blur(10px) opacity(1) brightness(1);
}
#contact_form input {
  //padding-top: 1vh;
  //padding-bottom: 1vh;
  padding-top: calc(0.5rem + 0.4vh)!important;
  padding-bottom: calc(0.5rem + 0.4vh)!important;
}
#contact_form .title h1 {
  color: $buttonorange;
  font-size: 15.2px;
  font-family: Messiri-Bold;
  border-bottom: 1.5px solid;
  display: inline-block;
  line-height: 0.9;
}
#contact_form .description p {
  color: $definitionblue;
  font-size: 10.24px;
}
#contact_form input::-webkit-outer-spin-button,
#contact_form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#contact_form input[type=number] {
  -moz-appearance: textfield;
}
#contact_form form input, #contact_form form input::placeholder, #contact_form form textarea, #contact_form form textarea::placeholder{
  color: $definitionblue;
  font-size: 11.24px;
}
#contact_form form button{
  font-size: 11.88px;
  font-family: Messiri-Medium;
  //width: 20vmin;
  //min-width: 19vmin;
}
#contact_form form input:focus, #contact_form form textarea:focus, #contact_form form input button .send_button:focus{
  box-shadow: none;
  border: 1px solid rgba(46, 49, 146, 0.8);
}
#contact_form .form-control{
  border: 1px solid rgba(46, 49, 146, 0.8);
}
#contact_form form textarea{
  resize: none;
}
#contact_form form textarea, #contact_form form input{
  background: none;
  border-radius: 0;
}
#contact_form input:-webkit-autofill, #contact_form input:-webkit-autofill:active, #contact_form input:-webkit-autofill:focus{
  transition: background-color 5000s;
  -webkit-text-fill-color: $definitionblue;
}
#contact_form form button {
  color: $primarywhite;
  text-decoration: underline;
  background: $definitionCardBlue;
  border: none;
  border-radius: 0;
  text-align: center !important;
}
#contact_form form button span img {
  width: 12.81px;
  height: 12.81px;
}
#contact_form .red-border {
  border: 1px solid #cc0000 !important;
}
#contact_form .error_message {
  color: #cc0000;
  font-size: 9.24px;
  float: right;
  padding: 0 10px;
}
#contact_form .success_message {
  color: $buttonorange;
  font-size: 12.2px;
}
#contact_form button {
  width: 97.4px;
  height: 36.3px;
}
#contact_form .spinner-border {
  width: 14px;
  height: 14px;
}
@media screen and (max-width: 768px) {
  #contact_form {
    margin-right: 0!important;
    margin-left: 0!important;
  }
  //#contact_form .title h1 {
  //  font-size: 3.2vmin;
  //}
  //#contact_form .description p {
  //  font-size: 2.6vmin;
  //}
  //#contact_form form input, #contact_form form input::placeholder, #contact_form form textarea, #contact_form form textarea::placeholder{
  //  font-size: 2.6vmin;
  //}
  //#contact_form form button{
  //  font-size: 3vmin;
  //}
  //#contact_form .error_message {
  //  font-size: 2.4vmin;
  //}
  //#contact_form .success_message {
  //  font-size: 2.8vmin;
  //}
  //#form .spinner-border {
  //  width: 0.8rem;
  //  height: 0.8rem;
  //}
}
@media screen  and (max-width: 992px) and (min-width: 768px) {
  //#contact_form .title h1 {
  //  font-size: 2.4vmin;
  //}
  //#contact_form .description p {
  //  font-size: 1.8vmin;
  //}
  //#contact_form form input, #contact_form form input::placeholder, #contact_form form textarea, #contact_form form textarea::placeholder{
  //  font-size: 1.8vmin;
  //}
  //#contact_form form button{
  //  font-size: 2.2vmin;
  //}
  //#contact_form .error_message {
  //  font-size: 1.6vmin;
  //}
  //#contact_form .success_message {
  //  font-size: 2vmin;
  //}
}

</style>
