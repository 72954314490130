<template>
  <div id="location" class="" v-on:click.self="closePopUp">
      <span class="point">
        <span v-on:click="openPopUp">
          <img src="../../assets/icons/point.svg">
          <div id="pop_up" class="pop_up p-3">
            <div class="company mb-2">
              {{$t('footer.company')}}
            </div>
            <div class="location mb-3">
<!--              {{card[2].cardDescription}}-->
              {{locationInfo}}
            </div>
            <div class="maps">
              <a :href="mapUrl" target="_blank">{{$t('pop_up.view')}}</a>
            </div>
          </div>
        </span>
      </span>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Location',
  props: {
    card: {
      cardDescription: String
    },
    url: String
  },
  data () {
    return {
      openPop: false,
      mapUrl: '',
      locationInfo: ''
    }
  },
  methods: {
    openPopUp () {
      document.getElementById('pop_up').style.display = 'block'
      this.openPop = true
    },
    closePopUp () {
      if (this.openPop) {
        document.getElementById('pop_up').style.display = 'none'
      }
    },
    getPopUpInfo () {
      this.mapUrl = this.url
      this.locationInfo = this.card[2].cardDescription
    }
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#location').css('margin-right', '1.5rem')
    } else {
      $('#location').css('margin-left', '1.5rem')
    }
  },
  created () {
    this.getPopUpInfo()
  },
  watch: {
    card (val) {
      this.getPopUpInfo()
    },
    url (val) {
      this.getPopUpInfo()
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
#location {
  background: url("../../assets/images/map.png")no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100%;
  position: relative;
  font-size: 13.5px;
}
#location .point {
  position: absolute;
  left: 20%;
  bottom: 57%;
  cursor: pointer;
}
#location span {
  position: relative;
}
#location .pop_up {
  display: none;
  position: absolute;
  left: 100%;
  width: 188.44px;
  //height: 16.6vmin;
  background: $primarywhite;
  cursor: default;
  //transition: .5s;
}
#location .point img {
  width: 21.78px;
  height: 21.78px;
}
#location .company {
  font-family: Messiri-Bold;
  color: $definitionblue;
}
#location .location {
  color: $definitionblue;
}
#location .maps a {
  color: $orangecarousel!important;
  font-family: Messiri-Bold!important;
}
@media screen and (max-width: 768px) {
  #location {
    height: 50vh!important;
    margin-right: 0!important;
    margin-left: 0!important;
    //font-size: 2.8vmin;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px){
  #location .point img {
    //width: 3.5vmin;
    //height: 3.5vmin;
  }
  #location .pop_up {
    //width: 38.4vmin;
    //height: 17.6vmin;
  }
  #location {
    //font-size: 2vmin;
  }
}
@media screen and (max-width: 768px){
  #location .point img {
    //width: 4.3vmin;
    //height: 4.3vmin;
  }
  #location .pop_up {
    //width: 48.4vmin;
    //height: 18.6vmin;
  }
}
@media screen and (max-width: 480px) {
  #location {
    //width: 4.3vmin;
    //height: 4.3vmin;
    //font-size: 13.5px!important;
  }
}
</style>
