<template>
  <div id="app">
<!--    <div id="nav">-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </div>-->
    <router-view/>
  </div>
</template>

<script>
// import i18n from './i18n'

export default {
  // data () {
  //   return {
  //     // lang: ''
  //   }
  // }
  // closeNav () {
  //   document.getElementById('sidebar').style.width = '0'
  // }
  // methods: {
  //   changeLangAr () {
  //     localStorage.setItem('lang', 'ar')
  //     // console.log('lang1', i18n.locale)
  //     // console.log('lang1', localStorage.getItem('lang'))
  //     location.reload()
  //     // this.ar_lang = true
  //   },
  //   changeLangEn () {
  //     localStorage.setItem('lang', 'en')
  //     // console.log('lang2', i18n.locale)
  //     // console.log('lang2', localStorage.getItem('lang'))
  //     location.reload()
  //   }
  // }
  metaInfo: {
    title: ' للمجبول البيتوني ومواد البناء في دمشق',
    titleTemplate: '%s | شركة داما',
    // lang: 'en-US',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'داما هي شركة وطنية رائدة متخصصة بإنتاج المجبول البيتوني ومواد البناء بجودة عالية ضمن المواصفات القياسية داخل العاصمة دمشق' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  created () {
    // localStorage.setItem('lang', 'ar')
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
    localStorage.setItem('initCarousel', Number(0))
    localStorage.setItem('initCardSlider', Number(0))
  }
}
</script>
<style lang="scss">
@import "src/scss/main";
  /*arabic fonts*/
  @font-face {
    src: url("assets/fonts/ElMessiri-Regular.ttf");
    font-family: Messiri-Regular;
  }
  @font-face {
    src: url("assets/fonts/ElMessiri-Bold.ttf");
    font-family: Messiri-Bold;
  }
  @font-face {
    src: url("assets/fonts/ElMessiri-SemiBold.ttf");
    font-family: Messiri-SemiBold;
  }
  @font-face {
    src: url("assets/fonts/ElMessiri-Medium.ttf");
    font-family: Messiri-Medium;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFFFFF;
  font-size: 1.8vmin;
  font-family: Messiri-Regular;
  //text-underline-position: under!important;
  //text-decoration-thickness: 2px !important;
  cursor: default;
  //background: url("assets/images/texture2.png") #E1E1E1;
  background: #E1E1E1;
}
 .red-border {
  border: 1px solid #cc0000 !important;
}
 .error_message {
  color: #cc0000;
  font-size: 1.6vmin;
  //float: right;
}

</style>
