<template>
    <section id="dama_clients" class="slick_padding">
    <div class="py-5 layer">
      <div class="">
        <h1 class="title mb-2"> {{$t('clients.title')}} </h1>
        <p class="description pb-lg-4"> {{$t('clients.description')}} </p>
      </div>
      <div class="row">
        <div>
<!--          <VueSlickCarousel v-bind="settings" :dots="false" ref="carousel" v-if="results.length>0" :initialSlide="initSliderVal" @afterChange="changeVal($event)">-->
          <VueSlickCarousel v-bind="settings" :dots="false" ref="carousel" v-if="results.length>0">
            <div class="py-5" v-for="(card,i) in results" :key="i">
              <client-card :card="card"/>
            </div>
          </VueSlickCarousel>
          <a class="prev d-flex justify-content-center align-items-center" v-on:click="prev" v-if="results.length>0">
            <img src="../../assets/icons/left_gray.svg">
          </a>
          <a class="next d-flex justify-content-center align-items-center" v-on:click="next" v-if="results.length>0">
            <img src="../../assets/icons/right_gray.svg">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ClientCard from './Components/ClientCard'
import ClientRepository from '../../Repositories/ClientRepository'
import Constants from '../../Plugins/Constants'

export default {
  name: 'DamaClients',
  components: { ClientCard, VueSlickCarousel },
  data () {
    // baseUrl: Constants.siteUrl
    // base: Constants.siteUrl
    // const jsonld = {
    //   '@context': 'https://schema.org',
    //   '@type': 'BreadcrumbList',
    //   itemListElement: [{
    //     '@type': 'ListItem',
    //     position: 1,
    //     name: 'Books',
    //     item: 'https://example.com/books'
    //   }, {
    //     '@type': 'ListItem',
    //     position: 2,
    //     name: 'The Lord of the Rings',
    //     item: 'https://example.com/books/the-lord-of-the-rings'
    //   }]
    // }
    return {
      markups: [],
      // jsonld,
      clientRepository: new ClientRepository(),
      // cards: [
      //   {
      //     cardTitle: this.$t('services.services[0].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[0].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[1].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[1].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[2].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[2].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[3].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[3].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[4].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[4].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[5].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[5].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[6].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[6].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[7].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[7].card_description')
      //   },
      //   {
      //     cardTitle: this.$t('services.services[8].card_title'),
      //     cardIcon: require('@/assets/icons/service_icon.svg'),
      //     cardDescription: this.$t('services.services[8].card_description')
      //   }
      // ],
      results: [],
      numPages: 0,
      page: 1,
      per_page: 4,
      show: [],
      settings: {
        dots: false,
        arrows: false,
        focusOnSelect: false,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 2,
        responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 1260,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          // {
          //   breakpoint: 999,
          //   settings: {
          //     slidesToShow: 2,
          //     slidesToScroll: 2,
          //     initialSlide: 1
          //   }
          // },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          }
        ]
      }
    }
  },
  metaInfo () {
    return {
      // title: `${this.seoTitle}`,
      // meta: [
      //   {name: "robots", content: "index,follow"},
      //   {
      //     name: 'description',
      //     content: `${this.seoContent}`
      //
      //   }
      //
      // ],
      // link: [
      //   {rel: 'favicon', href: 'logo.ico'}
      // ],
      script: [{
        type: 'application/ld+json',
        // json: this.markups
        json: {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          '@graph': this.markups
        }
      }]
    }
  },
  methods: {
    async getPage () {
      // var currentWidth = window.innerWidth
      // console.log('currentWidth', currentWidth)
      // if (currentWidth < 999 && currentWidth > 767) {
      //   this.per_page = 2
      // } else if (currentWidth < 767) {
      //   this.per_page = 1
      // }
      // console.log('our carousel is ready', slideIndex)
      const x = await this.clientRepository.getPage(this.page)
      this.results = x.content
      let i = 0
      for (i = 0; i < this.results.length; i++) {
        this.markups.push({
          '@type': 'ListItem',
          position: i + 1,
          name: this.results[i].title,
          item: Constants.siteUrl + 'SevicesDetails/' + this.results[i].id
        })
      }
      // var i = 0
      // var item = []
      // item = x.content
      // for (i = 0; i < item.length; i++) {
      //   this.results.push(item[i])
      // }
      // this.$refs.carousel.reSlick()
      this.numPages = x.lastPage
      // console.log('services-results', this.results)
    },
    async next () {
      // if (this.page < this.numPages) {
      //   this.page += 1
      //   await this.getPage()
      //   this.$refs.carousel.next()
      // }
      // this.$refs.carousel.reInit()
      this.$refs.carousel.next()
    },
    async prev () {
      // if (this.page !== 1) {
      //   this.page -= 1
      //   await this.getPage()
      //   this.$refs.carousel.prev()
      // }
      this.$refs.carousel.prev()
    }
    // changeVal (slideIndex) {
    //   localStorage.removeItem('initCardSlider')
    //   localStorage.setItem('initCardSlider', Number(slideIndex))
    //   // return
    //   // }
    // }
  },
  computed: {
    // initSliderVal () {
    //   return Number(localStorage.getItem('initCardSlider'))
    // }
  },
  created () {
    this.getPage()
  }
}
</script>
<style lang="scss">
@import "src/scss/main";
#dama_clients .custom-dot {
  color: red;
  //background-color: red;
}
#dama_clients {
  overflow: hidden;
  //background: url("../../assets/images/services_texture.png") no-repeat center center rgba(225,225,225,0.7);
  background: url("../../assets/images/mission_texture.png") no-repeat center center rgba(255,255,255,0.5);
  background-size: cover;
  position: relative;
  min-height: 356.6244px;
}
#dama_clients .layer {
  //background: url("../../assets/icons/water.svg") no-repeat;
  //background-position-x: center;
  //background-position-y: 70%;
  //background-size: 80% 80%;
}
#dama_clients .title {
  font-family: Messiri-Bold;
  font-size: 29.7px;
  border-bottom: 1.5px solid;
  color: $definitionCardBlue;
  display: inline-block;
  line-height: 0.7;
}
#dama_clients .description {
  font-size: 16.5px;
  color: $definitionblack;
}
#dama_clients .slick-next:before{
  content: url("../../assets/icons/right_service.svg");
}
#dama_clients .slick-prev:before{
  content: url("../../assets/icons/left_service.svg");
}
#dama_clients .slick-prev {
  left: -23px;
}
#dama_clients .slick-next {
  right: -23px;
}
#dama_clients .slick-prev,#dama_clients .slick-next{
  height: unset !important;
  width: unset !important;
  z-index: 100000;
}
#dama_clients .slick-list[data-v-3d1a4f76]{
  overflow: visible !important;
}
 .slick_padding {
  padding-left: 1.5rem!important;
  padding-right: 1.5rem!important;
}
 #dama_clients .prev {
   top: 50%;
   margin-top: 18.5px;
   left: 0px;
   position: absolute;
   width: 43.56px;
   height: 81.84px;
   background-color: rgba(255,255,255,0.4) !important;
   backdrop-filter: blur(11px) opacity(1);
   -webkit-backdrop-filter: blur(11px) opacity(1);
   -moz-backdrop-filter: blur(11px) opacity(1);
   -o-backdrop-filter: blur(11px) opacity(1);
   cursor: pointer;
 }
 #dama_clients .next {
   top: 50%;
   margin-top: 18.5px;
   right: 0;
   position: absolute;
   width: 43.56px;
   height: 81.84px;
   background-color: rgba(255,255,255,0.4) !important;
   backdrop-filter: blur(11px) opacity(1);
   -webkit-backdrop-filter: blur(11px) opacity(1);
   -moz-backdrop-filter: blur(11px) opacity(1);
   -o-backdrop-filter: blur(11px) opacity(1);
   cursor: pointer;
 }
 #dama_clients .next img, #dama_clients .prev img {
   //width: 1.335vmin;
   //height: 2.67vmin;
   width: 8.811px;
   height: 17.6px;
   //width: 13.35 vmin;
   //height: 26.7 vmin;
 }
@media screen and (max-width: 768px) {
  //#dama_services .title {
  //  font-size: 5.5vmin;
  //}
  //#dama_services .description {
  //  font-size: 3.5vmin;
  //}
  //#dama_services .prev, #dama_services .next {
  //  width: 8.6vmin;
  //  height: 14.4vmin;
  //}
  //#dama_services .next img, #dama_services .prev img {
  //  width: 2.335vmin;
  //  height: 3.67vmin;
  //}
}
@media screen and (max-width: 999px) and (min-width: 768px) {
  //#dama_services .title {
  //  font-size: 4.7vmin;
  //}
  //#dama_services .description {
  //  font-size: 2.7vmin;
  //}
  //dama_services .next img, #dama_services .prev img {
  //  width: 2.335vmin;
  //  height: 3.67vmin;
  //}
  //#dama_services .prev, #dama_services .next {
  //  width: 7.6vmin;
  //  height: 13.4vmin;
  //}
}
@media screen and (min-width: 992px){
  #dama_clients .description {
    padding: 0 15%;
  }
}
</style>
