<template>
    <div id="services" v-on:click="closeNav">
      <dama-services/>
    </div>
</template>

<script>

import DamaServices from '../components/Services/DamaServices'
export default {
  name: 'Services',
  components: { DamaServices },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    }
  }
}
</script>

<style scoped>

#services {
  margin-top: -100px;
  padding-top: 100px;
}
</style>
