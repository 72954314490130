<template>
  <footer v-on:click="closeNav">
    <div class="row ps-md-4 ps-3  pe-md-4 pe-3 mt-md-3 mt-2 mb-md-3 mb-2 py-1">
      <div class="col-4 d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <div class="copy_rights">{{$t('copy_right')}} &copy;</div>
          </div>
          <div class="col-12">
            <div class="company">{{$t('footer.company')}}</div>
          </div>
        </div>
      </div>
      <div class="col-4 d-flex justify-content-center align-items-center">
        <div class="icons">
          <a class="icon" :href="instagram" target="_blank">
            <img class="" src="../../assets/icons/instagram_blue.svg" alt="Instagram icon">
          </a>
          <a class="icon ps-3 pe-3" :href="youtube" target="_blank">
            <img class="" src="../../assets/icons/youtube_blue.svg" alt="Youtube icon">
          </a>
          <a class="icon" :href="facebook" target="_blank">
            <img class="" src="../../assets/icons/facebook_blue.svg" alt="Facebook icon">
          </a>
        </div>
      </div>
      <div class="col-4 d-flex align-items-center justify-content-end">
        <div class="row">
          <div class="col-12">
            <div class="rights">{{$t('footer.rights')}}</div>
          </div>
          <div class="col-12">
            <div class="year">{{$t('footer.year')}}</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import i18n from '../../i18n'

import $ from 'jquery'
// import ContactsRepository from '../../Repositories/ContactsRepository'

export default {
  name: 'Footer',
  props: {
    contactInfo: {
    }
  },
  data () {
    return {
      facebook: '',
      instagram: '',
      youtube: ''
      // ContactsRepo: new ContactsRepository()
    }
  },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    },
    get_contacts () {
      // const response = await this.ContactsRepo.get()
      this.facebook = this.contactInfo.facebook
      this.instagram = this.contactInfo.instagram
      this.youtube = this.contactInfo.youtube
    }
  },
  created () {
    this.get_contacts()
  },
  watch: {
    contactInfo (val) {
      this.get_contacts()
    }
    // get_contacts (val) {
    //   // console.log('test', val)
    // }
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('footer').css('direction', 'rtl')
    } else {
      $('footer').css('direction', 'ltr')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
footer {
  //direction: rtl;
  overflow: hidden;
  background-color: rgba(255,255,255,0.3) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  position: absolute;
  bottom: 0;
  width: 100%;
}
footer .rights, footer .year, footer .copy_rights {
  color: $definitionblue;
}
footer .rights {
  font-size: 9.9px;
}
footer .year {
  font-size: 9.24px;
}
footer .rights, footer .year {
  text-align: end;
}
footer .company {
  font-size: 7.6px;
  color: $buttonorange;
}
footer .copy_rights {
  font-size: 9.56px;
}
footer .company, footer .copy_rights {
  text-align: start;
}
footer .icons .icon img {
  width: 20px;
}
@media screen and (max-width: 768px) {
  //footer .rights {
  //  font-size: 2.5vmin;
  //}
  //footer .year {
  //  font-size: 2.3vmin;
  //}
  //footer .company {
  //  font-size: 1.9vmin;
  //}
  //footer .copy_rights {
  //  font-size: 2.4vmin;
  //}
}
@media screen  and (max-width: 992px) and (min-width: 768px) {
  //footer .rights {
  //  font-size: 2vmin;
  //}
  //footer .year {
  //  font-size: 1.8vmin;
  //}
  //footer .company {
  //  font-size: 1.4vmin;
  //}
  //footer .copy_rights {
  //  font-size: 1.9vmin;
  //}
}

</style>
