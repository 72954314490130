<template>
    <div id="clients" v-on:click="closeNav">
      <dama-clients/>
    </div>
</template>

<script>

import DamaClients from '../components/Clients/DamaClients'
export default {
  name: 'Clients',
  components: { DamaClients },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    }
  }
}
</script>

<style scoped>
#clients {
  margin-top: -100px;
  padding-top: 100px;
}
</style>
