import AxiosClient from './AxiosClient'

export default class CrudRepository {
  constructor (resource) {
    this.resource = 'api/' + resource
  }

  async create (item) {
    const { data } = await AxiosClient.post(this.resource, item)
    return data
  }

  async get () {
    return await AxiosClient.get(this.resource)
  }
}
