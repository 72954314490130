<template>
  <section id="contact" v-on:click="closeNav">
    <div class="texture">
      <div class="row pt-5">
        <div class="col-12 title pb-1">
          <h1 class="">{{$t('contact.title')}}</h1>
        </div>
        <div class="col-12 pb-4 description">
          <p class="">{{$t('contact.description')}}</p>
        </div>
      </div>
      <div class="row media">
        <div class="col-4 text-center" v-for="(card,i) in cards" :key="i">
          <div class="icon"> <img :src="card.cardIcon"> </div>
          <h1 class="contact_title pt-2"> {{card.cardTitle}} </h1>
          <div class="contact_description"> {{card.cardDescription}} </div>
        </div>
      </div>
      <div class="row pt-4 reverse">
        <div class="col-md-6">
          <contact-form/>
        </div>
        <div class="col-md-6">
          <location :card="cards" :url="url"/>
        </div>
      </div>
      <Footer :contactInfo="contactInfo"/>
    </div>
  </section>
</template>

<script>
import Location from '../components/Contact/Location'
import ContactForm from '../components/Contact/ContactForm'
import Footer from '../components/Shared/Footer'
import $ from 'jquery'
// import ContactsRepository from '../Repositories/ContactsRepository'
// import ContactsRepository from '../Repositories/ContactsRepository'
export default {
  name: 'Contact',
  props: {
    contactInfo: {
    }
  },
  components: { Footer, ContactForm, Location },
  data () {
    return {
      // ContactsRepository: new ContactsRepository(),
      // email: '',
      // phone: '',
      // address: '',
      contactResult: {},
      // ContactRepo: new ContactsRepository(),
      url: '',
      cards: [
        {
          cardTitle: this.$t('contact.email'),
          cardDescription: '',
          cardIcon: require('../assets/icons/message.svg')
        },
        {
          cardTitle: this.$t('contact.phone'),
          cardDescription: '',
          cardIcon: require('../assets/icons/phone.svg')
        },
        {
          cardTitle: this.$t('contact.address'),
          cardDescription: '',
          cardIcon: require('../assets/icons/location.svg')
        }
      ]
    }
  },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    },
    // async get_contacts () {
    //   const response = await this.ContactRepo.get()
    //   // this.facebook = response.data.body.facebook
    //   // this.instagram = response.data.body.instagram
    //   // this.youtube = response.data.body.youtube
    //   // console.log('FATIMA', response)
    //   this.cards[2].cardDescription = response.data.body.address
    //   this.cards[1].cardDescription = response.data.body.phone
    //   this.cards[0].cardDescription = response.data.body.email
    //   this.url = response.data.body.url
    //   this.contactResult = response.data.body
    //   this.$emit('contact', this.contactResult)
    //   // console.log('contact created', this.contactResult)
    //   // this.test = response.data.body.facebook
    //   // console.log('FATIMaaaaA', this.contactResult)
    // },
    get_contacts () {
      // const response = await this.ContactsRepository.get()
      this.contactResult = this.contactInfo
      this.cards[2].cardDescription = this.contactResult.address
      this.cards[1].cardDescription = this.contactResult.phone
      this.cards[0].cardDescription = this.contactResult.email
      this.url = this.contactResult.url
    }
  },
  created () {
    this.get_contacts()
    // console.log('Contact result', this.contactResult)
    // console.log('FATIMaaaaA Contact mmmnmnm', this.contactResult)
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#contact').css('direction', 'rtl')
    } else {
      $('#contact').css('direction', 'ltr')
    }
  },
  watch: {
    contactInfo (val) {
      // this.contactResult = val
      // console.log('watch val', val, 'result value', this.contactResult)
      // this.cards[2].cardDescription = this.contactResult.address
      // this.cards[1].cardDescription = this.contactResult.phone
      // this.cards[0].cardDescription = this.contactResult.email
      // this.url = this.contactResult.url
      this.get_contacts()
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
#contact {
  overflow: hidden;
  background: url("../assets/images/contact.png") no-repeat;
  background-size: cover;
  position: relative;
  margin-top: -100px;
  padding-top: 100px;
}
#contact .texture {
  background-image: url("../assets/images/contact_us_texture.png");
  padding-bottom: 6.5rem;
}
#contact .title h1 {
  font-family: Messiri-Bold;
  font-size: 29.7px;
  color: $definitionCardBlue;
  border-bottom: 1.5px solid;
  display: inline-block;
  line-height: 0.9;
}
#contact .icon img {
  width: 18.41px;
  height: 17.26px;
}
#contact .description p {
  color: $definitionblack;
  font-size: 14.52px;
}
#contact .contact_title {
  font-size: 14.5px;
  font-family: Messiri-SemiBold;
  color: $buttonorange;
}
#contact .contact_description {
  color: $definitionblue;
  font-size: 14.5px;
  font-family: Messiri-SemiBold;
}
#contact .media {
  padding: 0 18%;
  direction: ltr!important;
}
#contact .description {
  padding: 0 28%;
}
@media screen and (max-width: 768px) {
  #contact .reverse {
    flex-direction: column-reverse!important;
  }
  //#contact .title h1 {
  //  font-size: 5.5vmin;
  //}
  //#contact .description p {
  //  font-size: 3.2vmin;
  //}
  //#contact .contact_title, #contact .contact_description {
  //  font-size: 3vmin;
  //}
  #contact .media {
    padding: 0 4%;
  }
  #contact .description {
    padding: 0 10%;
  }
  #contact .texture {
    padding-bottom: 5.5rem;
  }
}
@media screen and (max-width: 768px){
  #contact {
    background: url("../assets/images/contact_rotate.png") no-repeat center center;
    background-size: cover;
  }
}
@media screen  and (max-width: 992px) and (min-width: 768px) {
  //#contact .title h1 {
  //  font-size: 4.7vmin;
  //}
  //#contact .description p {
  //  font-size: 2.4vmin;
  //}
  //#contact .contact_title, #contact .contact_description {
  //  font-size: 2.7vmin;
  //}
  #contact .media {
    padding: 0 15%;
  }
  #contact .description {
    padding: 0 26%;
  }
}
@media screen and (max-width: 480px) {
  #contact .contact_title {
    font-size: 13.5px;
  }
  #contact .contact_description {
    font-size: 11.5px;
  }
}

</style>
