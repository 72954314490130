<template>
  <div id="definition_card" class="d-flex justify-content-center align-items-center mt-xl-3 mt-5">
    <div class="main_card px-2">
      <div class="">
<!--        <div class="icon pb-lg-3 pb-3 pt-lg-5 pt-4">-->
        <div class="icon">
          <img :src="card.cardIcon" :alt="card.cardTitle">
        </div>
<!--        <div class="title pb-lg-4 pb-3">-->
        <div class="title">
          <h1> {{card.cardTitle}} </h1>
        </div>
<!--        <div class="body ps-3 pe-3 pb-4">-->
        <div class="body px-1" v-html="card.cardDescription">
<!--          <p> {{card.cardDescription}}</p>-->
        </div>
      </div>
<!--      <p>-->
<!--        الشفافية والصدق-->
<!--        <br/>-->
<!--       المرونة-->
<!--       <br/>-->
<!--        روح الفريق-->
<!--       <br/>-->
<!--       الالتزام بالجودة-->
<!--      </p>-->
<!--            <p>-->
<!--              Transparency & Honesty-->
<!--              <br/>-->
<!--              Flexibility-->
<!--              <br/>-->
<!--              Team Spirit-->
<!--              <br/>-->
<!--              Quality Commitment-->
<!--            </p>-->
    </div>
    <div class="border_card"></div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'DefinitionCard',
  props: {
    card: {
      cardTitle: String,
      CardDescription: String,
      CardIcon: String
    }
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#definition .body').addClass('ar')
    } else {
      $('#definition .body').addClass('en')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
  #definition_card {
    //filter: inset(5px 5px 10px rgba(255,255,255,0.25));
    //-webkit-filter: inset(5px 5px 10px rgba(255,255,255,0.25));
    //-moz-filter: inset(5px 5px 10px rgba(255,255,255,0.25));
    //-o-filter: inset(5px 5px 10px rgba(255,255,255,0.25));
    filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
    -webkit-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
    -moz-filter:  drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.2));
    -o-filter:  drop-shadow(15px 15px 30px rgba(0, 0, 0, 0.2));
    margin-bottom: 2rem;
  }
  #definition_card .main_card {
    clip-path: polygon(50% 0, 100% 20%, 100% 81%, 50% 100%, 0 81%, 0 20%);
    //width: 42.896vmin;
    //height: 46.14vmin;
    //width: 283.11px;
    //height: 294.10px;
    width: 273.1674px;
    height: 293.8254px;
    background: $lightGray;
    z-index: 100;
    position: absolute;
    box-shadow: inset 5px 5px 10px rgba(255,255,255,0.25), inset -5px -5px 10px rgba(255,255,255,0.25);
  }
  #definition_card .icon img {
    //width: 44.37px;
    //height: 31.01px;
    //width: 54.37px;
    //height: 41.01px;
    width: 42.37px;
    height: 41.01px;
  }
  #definition_card .title h1{
    color: $definitionCardBlue;
    font-size: 23.1px;
    font-family: Messiri-Bold;
  }
  #definition .body {
    color: $definitionblack;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* number of lines to show */
    -webkit-box-orient: vertical;
    //text-align: justify;
  }
  #definition .body.ar {
    direction: rtl;
  }
  #definition .body.en {
    direction: ltr;
  }
  #definition_card .border_card {
    //width: 36.896vmin;
    //height: 40.04vmin;
    //width: 237.94px;
    //height: 247.18px;
    width: 229.5876px;
    height: 238.4976px;
    bottom: -35.04px;
    z-index: 90;
    position: relative;
    background: $definitionblue;
    clip-path: polygon(50% 0, 100% 20%, 100% 81%, 50% 100%, 0 81%, 0 20%);
  }
  #definition_card .icon {
    margin: 1.7rem 0 0.3rem 0;
  }
  #definition_card .title {
    margin-bottom: 0.8rem;
  }
@media screen  and (max-width: 992px) {
  #definition_card .main_card {
    //width: 53.866vmin;
    //height: 55.957vmin;
  }
  #definition_card .mt-5 {
    //margin-top: 4rem;
  }
  #definition_card .border_card {
    //width: 47.866vmin;
    //height: 49.957vmin;
    //bottom: -4.4vmin;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px){
  #definition .body p {
    //font-size: 2.4vmin;
  }
  #definition_card .title h1{
    //font-size: 3.9vmin;
  }
  #definition_card .icon img {
    //width: 6.8vmin;
    //height: 6.8vmin;
  }
  #definition_card .icon {
    //margin: 10vh 0 3vh 0;
  }
  #definition_card .title {
    //margin-bottom: 3vh;
  }
}
@media screen and (max-width: 768px){
  #definition .body p {
    //font-size: 3vmin;
  }
  #definition_card .title h1{
    //font-size: 4.1vmin;
  }
  #definition_card .icon img {
    //width: 7.6vmin;
    //height: 7.6vmin;
  }
  #definition_card .icon {
    //margin: 3.5vh 0 1.3vh 0;
  }
  #definition_card .title {
    //margin-bottom: 1.7vh;
  }
}
</style>
