<template>
  <aside id="sidebar" class="d-lg-none" v-on:click="closeNav">
    <nav>
      <ul class="navbar-nav width_100 row d-lg-none">
        <li class="nav-item col-12">
          <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav">×</a>
        </li>
        <li class="nav-item col-12 mt-5 height-item">
          <a class="nav-link" href="#">{{ $t('navbar.home') }}</a>
        </li>
        <li class="nav-item col-12 height-item">
          <a class="nav-link" href="#about_us">{{ $t('navbar.about') }}</a>
        </li>
        <li class="nav-item col-12 height-item">
          <a class="nav-link" href="#services">{{ $t('navbar.services') }}</a>
        </li>
        <li class="nav-item col-12 height-item">
          <a class="nav-link" href="#clients">{{ $t('navbar.clients') }}</a>
        </li>
        <li class="nav-item col-12 height-item">
          <a class="nav-link" href="#numbers">{{ $t('navbar.achievements') }}</a>
        </li>
        <li class="nav-item col-12 height-item">
          <a class="nav-link" href="#news">{{ $t('navbar.news') }}</a>
        </li>
        <li class="nav-item col-12 height-item">
          <a class="nav-link" href="#contact">{{ $t('navbar.contact') }}</a>
        </li>
        <router-link :to="'/recruitment'">
          <li class="nav-item col-12 height-item  d-flex align-items-center justify-content-center">
            <a class="navbar_join" href="#">{{ $t('join_us') }}</a>
          </li>
        </router-link>
        <li class="nav-item col-12 lang height-lang  d-flex align-items-start justify-content-center">
          <span id="english" @click="changeLangEn">{{$t('english')}}</span> / <span id="arabic" @click="changeLangAr">{{$t('arabic')}}</span>
        </li>
        <li class="nav-item col-12 footer">
          <a class="rights" href="#">© {{$t('copy_right')}}</a>
        </li>
        <li class="nav-item col-12 footer d-flex justify-content-center">
          <a class="company" href="#">{{$t('footer.company')}}</a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Sidebar',
  methods: {
    openNav () {
      document.getElementById('sidebar').style.width = '300px'
    },
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    },
    changeLangAr () {
      localStorage.setItem('lang', 'ar')
      // console.log('lang1', i18n.locale)
      // console.log('lang1', localStorage.getItem('lang'))
      location.reload()
    },
    changeLangEn () {
      localStorage.setItem('lang', 'en')
      // console.log('lang2', i18n.locale)
      // console.log('lang2', localStorage.getItem('lang'))
      location.reload()
    }
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      // console.log('watch:', localStorage.getItem('lang'))
      $('#sidebar').css('left', '0')
      $('#sidebar .closebtn').css('left', '-30px')
      $('#sidebar #arabic').css('text-decoration', 'underline')
      $('#sidebar #english').addClass('disabled_lang')
    } else {
      $('#sidebar').css('right', '0')
      $('#sidebar .closebtn ').css('right', '30px')
      $('#sidebar #english').css('text-decoration', 'underline')
      $('#sidebar #arabic').addClass('disabled_lang')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";

.disabled_lang {
  color: rgba(255,255,255,0.4)!important;
}
#sidebar::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
//#sidebar .p-abso {
//  //position: absolute;
//  //bottom: 2vh;
//  height: 15vh;
//}
.height-item {
  height: 7vh;
}
.height-lang {
  height: 12vh;
}
.height-contact {
  height: 10vh;
}
#sidebar {
  position: fixed;
  width: 0;
  top: 0;
  bottom: 0;
  //left: 0;
  z-index: 500001;
  display: block;
  padding-top: calc(4vw + 4vh);
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  //overflow-y: hidden;
  background-color: rgba(0,0,0,0.25) !important;
  -webkit-backdrop-filter: blur(27px) !important;
  -moz-backdrop-filter: blur(27px) !important;
  -o-backdrop-filter: blur(27px) !important;
  backdrop-filter: blur(27px) !important;
  //background-color: rgba(0,0,0,0.9) !important;
  //-webkit-backdrop-filter: blur(20px) !important;
  //-o-backdrop-filter: blur(20px) !important;
  //-moz-backdrop-filter: blur(20px) !important;
  //backdrop-filter: blur(20px) !important;
  transition: 0.5s;
}
#sidebar a, #sidebar .lang {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 12.88px;
  color: #FFF;
  //display: block;
  transition: 0.3s;
  cursor: pointer;
}
#sidebar .company {
  font-size: 13.2px!important;
  padding-top: 4px!important;
}
#sidebar .rights {
  font-size: 14px!important;
  padding-bottom: 0!important;
}
//#sidebar .rights a {
//}
#sidebar .closebtn {
  position: absolute;
  top: -2.2px;
  //left: -30px;
  font-size: 40px;
  margin-left: 50px;
  transition: 0.3s;
}
#servicesDropdown {
  padding: 0;
}
#servicesDropdown .dropdown-item:hover {
  background: transparent;
}
#sidebar .lang a {
  padding:0;
}
#sidebar .footer .company {
  color: $primarywhite!important;
  //color: $definitionblue!important;

}
#sidebar .footer .rights {
  color: $orangecarousel !important;
}
@media screen and (max-height: 576px) {
  //#sidebar {
  //  padding-top: 15px;
  //}
}
@media screen and (max-width: 768px) {
  //#sidebar .company {
  //  font-size: 2vmin!important;
  //}
  //#sidebar .rights {
  //  font-size: 2.7vmin!important;
  //}
}
@media screen  and (max-width: 992px) and (min-width: 768px) {
  //#sidebar .company {
  //  font-size: 1.8vmin!important;
  //}
  //#sidebar .rights {
  //  font-size: 2.3vmin!important;
  //}
}
/* Safari 10.1+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  #sidebar {
    background-color: rgba(0,0,0,0.9) !important;
    -webkit-backdrop-filter: blur(20px) !important;
    -o-backdrop-filter: blur(20px) !important;
    -moz-backdrop-filter: blur(20px) !important;
    backdrop-filter: blur(20px) !important;
  }
}}
@media screen and (max-width: 500px){
  //#sidebar .company {
  //  font-size: 3vmin!important;
  //}
  //#sidebar .rights {
  //  font-size: 3.7vmin!important;
  //}
}
</style>
