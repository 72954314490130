<template>
  <header id="navbar">
    <div class="row">
      <!--        <nav class="navbar navbar-expand-md navbar-dark" v-bind:class="[scrollPosition > window_height ? 'change_navbar_background' : '']">-->
      <nav class="navbar navbar-expand-md navbar-dark">
        <div class="container-fluid">
          <div class="col-md-4 col-12 d-flex justify-content-between align-items-center">
            <!--              <a class="navbar-brand" href="#"><img src="../../assets/logo 01.png"></a>-->
            <a class="navbar-brand" href="#"><img src="../../assets/Dama-logo-white.svg" alt="Dama logo"></a>
            <!--          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">-->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" v-on:click="openSide">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="col-md-8 d-flex align-items-center">
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav width_100">
                <div class="row width_100">
                  <div class="col-md-8 d-flex justify-content-around align-items-center">
                    <li class="nav-item">
                      <a class="nav-link" aria-current="page" href="#">{{$t('navbar.home')}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#about_us">{{$t('navbar.about')}}</a>
                    </li>
                    <!--                      <li class="nav-item dropdown position-relative">-->
                    <!--                        <a class="nav-link dropdown-toggle" href="#services" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">-->
                    <!--                          {{$t('navbar.services')}}-->
                    <!--                        </a>-->
                    <!--                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">-->
                    <!--                          <li><a class="dropdown-item" href="#">Action</a></li>-->
                    <!--                          <li><a class="dropdown-item" href="#">Another action</a></li>-->
                    <!--                          <li><a class="dropdown-item" href="#">Something else here</a></li>-->
                    <!--                        </ul>-->
                    <!--                      </li>-->
                    <li class="nav-item">
                      <a class="nav-link" href="#services">{{$t('navbar.services')}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#clients">{{$t('navbar.clients')}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#numbers">{{$t('navbar.achievements')}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#news">{{$t('navbar.news')}}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#contact">{{$t('navbar.contact')}}</a>
                    </li>
                    <!--                      <li class="nav-item">-->
                    <!--                        <select v-model="$i18n.locale">-->
                    <!--                          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>-->
                    <!--                        </select>-->
                    <!--                      </li>-->
                  </div>
                  <div class="col-md-4 d-flex justify-content-end align-items-center">
                    <router-link :to="'/recruitment'">
                      <li class="nav-item">
                        <a class="nav-link navbar_join" href="#">{{$t('join_us')}}</a>
                      </li>
                    </router-link>
                    <li class="nav-item lang">
                      <span class="lang1" id="arabic" @click="changeLangAr()">{{$t('arabic')}}</span> /
                      <span class="lang1" id="english" @click="changeLangEn()">{{$t('english')}}</span>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>

// import i18n from '../../i18n'
// import $ from 'jquery'
import $ from 'jquery'

export default {
  name: 'Navbar',
  data () {
    return {
      action: false,
      scrollPosition: null,
      window_height: null
      // ar_lang: false,
      // en_lang: false
      // langs: ['en', 'ar']
      // open_action: false
    }
  },
  methods: {
    openSide () {
      document.getElementById('sidebar').style.width = '300px'
      this.open_action = true
    },
    // closeNav () {
    //   if (this.open_action) {
    //     document.getElementById('sidebar').style.width = '0'
    //   }
    changeLangAr () {
      localStorage.setItem('lang', 'ar')
      // console.log('lang1', i18n.locale)
      // console.log('lang1', localStorage.getItem('lang'))
      location.reload()
    },
    changeLangEn () {
      localStorage.setItem('lang', 'en')
      // console.log('lang2', i18n.locale)
      // console.log('lang2', localStorage.getItem('lang'))
      location.reload()
    },
    newRoute () {
      this.$router.push('/')
    }
    // updateScroll () {
    //   // this.scrollPosition = window.scrollY
    // }
  },
  created () {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar')
    }
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      // console.log('watch:', localStorage.getItem('lang'))
      $('#navbar').css('direction', 'rtl')
      $('#navbar .navbar-brand').css('margin-right', '1.5rem')
      $('#navbar .navbar-toggler').css('margin-left', '0.25rem')
      $('#navbar .lang').css('margin-right', '1.5rem')
      $('#navbar #arabic').css('text-decoration', 'underline')
      $('#navbar #english').addClass('disabled_lang')
    } else {
      $('#navbar').css('direction', 'ltr')
      $('#navbar .navbar-brand').css('margin-left', '1.5rem')
      $('#navbar .navbar-toggler').css('margin-right', '0.25rem')
      $('#navbar .lang').css('margin-left', '1.5rem')
      $('#navbar #english').css('text-decoration', 'underline')
      $('#navbar #arabic').addClass('disabled_lang')
    }
  }
}

</script>

<style scoped lang="scss">
@import "src/scss/main";
.disabled_lang {
  color: rgba(255,255,255,0.4)!important;
}
#navbar {
  //direction: rtl;
  position: fixed;
  z-index: 500000;
  width: 100%;
  background-color: rgba(0,0,0,0.5) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
  text-underline-position: under!important;
}
#navbar .nav-item a, #navbar .nav-item span {
  font-size: 11.88px!important;
}
#navbar img {
  //height: 44.22px;
  //width: 133.36px;
  //width: 121.3278px;
  //height: 45.5202px;
  width: 125.3278px;
  height: 49.5202px;
  //min-height: 42.22px;
  //min-width: 127.36px;
}
#navbar .width_100 {
  width: 100%;
  padding: 0;
}
#navbar .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  width: 1.3em!important; height: 1.3em;
}
#navbar .navbar-dark .navbar-toggler {
  background: none!important;
  border: none;
}
#navbar .container-fluid {
  padding: 0;
}
#navbar .col-md-4, #navbar .col-md-8 {
  padding: 0;
}
#navbar .dropdown-menu {
  background-color: rgba(0,0,0,0.5) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
  border-radius: 0;
  z-index: 4000;
  font-size: 11.88px;
  position: fixed;
  top: 100%;
  margin-top: -0.15px;
  left: 50% !important;
  right: auto !important;
  text-align: center !important;
  transform: translate(-50%, 0) !important;
  -webkit-transform: translate(-50%, 0) !important;
  -o-transform: translate(-50%, 0) !important;
  -moz-transform: translate(-50%, 0) !important;
  border: 0;
}
#navbar .dropdown-menu a:hover {
  transform: scale(1.2);
}
#navbar .dropdown-menu .dropdown-item:hover {
  background: transparent;
}
#navbar .lang {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  #navbar .navbar-brand {
    margin-right: 15px;
  }
}
@media screen and (max-width: 576px) {
  //#navbar img {
  //  width: 17vh;
  //  min-width: 17vh;
  //  min-height: 5.5vh;
  //  height: 5.5vh;
  //}
}
@media screen and (max-width: 768px) and (min-width: 576px) {
  //#navbar img {
  //  width: 18.5vh;
  //  min-width: 18.5vh;
  //}
}
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    #navbar .dropdown-menu{
      top: unset !important;
      margin-top: 14px !important;
      //margin-top: 1.39vh !important;
    }
  }
}

</style>
