<template>
  <section id="vision_mission_values" class="pb-3">
    <div class="pt-lg-5 pt-4 pt-0 pb-4">
      <VueSlickCarousel v-bind="settings" :dots="false" ref="carousel" v-if="cards.length>0">
        <div class="py-5" v-for="(card,i) in cards" :key="i">
          <definition-card :card="card" :counter="i"/>
        </div>
      </VueSlickCarousel>
      <a class="prev d-flex justify-content-center align-items-center d-xl-none" v-on:click="prev">
        <img src="../../assets/icons/left_gray.svg">
      </a>
      <a class="next d-flex justify-content-center align-items-center d-xl-none" v-on:click="next">
        <img src="../../assets/icons/right_gray.svg">
      </a>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import DefinitionCard from './Components/DefinitionCard'
import AboutusRepository from '../../Repositories/AboutusRepository'

export default {
  name: 'VisionMissionValues',
  components: { DefinitionCard, VueSlickCarousel },
  props: {
    aboutInfo: {
    }
  },
  data () {
    return {
      // vision: '',
      // mission: '',
      // target: '',
      // showArrows: false,
      AboutUsRepository: new AboutusRepository(),
      cards: [
        {
          cardTitle: this.$t('definition.cards[2].card_title'),
          cardDescription: '',
          cardIcon: require('@/assets/icons/mission.svg')
        },
        {
          cardTitle: this.$t('definition.cards[3].card_title'),
          cardDescription: '',
          cardIcon: require('@/assets/icons/values.svg')
        },
        {
          cardTitle: this.$t('definition.cards[1].card_title'),
          cardDescription: '',
          cardIcon: require('@/assets/icons/goal.svg')
        },
        {
          cardTitle: this.$t('definition.cards[0].card_title'),
          cardDescription: '',
          cardIcon: require('@/assets/icons/vision.svg')
        }
      ],
      settings: {
        dots: false,
        arrows: false,
        focusOnSelect: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 4,
        responsive: [
          {
            breakpoint: 1420,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          // {
          //   breakpoint: 999,
          //   settings: {
          //     slidesToShow: 2,
          //     slidesToScroll: 2,
          //     initialSlide: 1
          //   }
          // },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          }
        ]
      }
    }
  },
  methods: {
    // async get_vision_mission_target () {
    //   const response = await this.AboutUsRepository.get()
    //   this.cards[0].cardDescription = response.data.body.vision
    //   this.cards[1].cardDescription = response.data.body.target
    //   this.cards[2].cardDescription = response.data.body.mission
    //   // console.log('his', response.data.body.vision)
    // },
    get_vision_mission_target () {
      // const response = await this.AboutUsRepository.get()
      this.cards[0].cardDescription = this.aboutInfo.target
      this.cards[1].cardDescription = this.aboutInfo.value
      this.cards[2].cardDescription = this.aboutInfo.mission
      this.cards[3].cardDescription = this.aboutInfo.vision
      // console.log('his', response.data.body.vision)
    },
    hideArrows () {
      // var currentWidth = window.innerWidth
      // if (currentWidth < 1260) {
      //   this.showArrows = true
      // } else {
      //   this.showArrows = false
      // }
    },
    async next () {
      // if (this.page < this.numPages) {
      //   this.page += 1
      //   await this.getPage()
      //   this.$refs.carousel.next()
      // }
      // this.$refs.carousel.reInit()
      this.$refs.carousel.next()
    },
    async prev () {
      // if (this.page !== 1) {
      //   this.page -= 1
      //   await this.getPage()
      //   this.$refs.carousel.prev()
      // }
      this.$refs.carousel.prev()
    }
  },
  created () {
    this.get_vision_mission_target()
    // window.addEventListener('resize', this.hideArrows)
  },
  destroyed () {
    // window.removeEventListener('resize', this.hideArrows)
  },
  watch: {
    aboutInfo (val) {
      this.get_vision_mission_target()
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
#vision_mission_values {
  //background: $lightGray;
  background: url("../../assets/images/mission_texture.png") no-repeat center center rgba(255,255,255,0.5);
  background-size: cover;
  overflow: hidden;
  position: relative;
}
#vision_mission_values .prev {
  top: calc(50% - 40.92px);
  margin-top: 18.5px;
  left: 0px;
  position: absolute;
  width: 43.56px;
  height: 81.84px;
  background-color: rgba(255,255,255,0.4) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
  cursor: pointer;
}
#vision_mission_values .next {
  top: calc(50% - 40.92px);
  margin-top: 18.5px;
  right: 0;
  position: absolute;
  width: 43.56px;
  height: 81.84px;
  background-color: rgba(255,255,255,0.4) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
  cursor: pointer;
}
#vision_mission_values .next img, #vision_mission_values .prev img {
  //width: 1.335vmin;
  //height: 2.67vmin;
  width: 8.811px;
  height: 17.6px;
  //width: 13.35 vmin;
  //height: 26.7 vmin;
}

</style>
