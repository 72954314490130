<template>
  <div id="service_card" class="d-flex justify-content-center align-items-center mt-lg-0 mt-0">
    <div class="main_card px-2">
      <div class="pt-1 row">
        <div class="col-12">
          <div class="icon1 mb-2">
            <img :src="baseUrl + card.icon" :alt="card.title">
          </div>
        </div>
        <div class="col-12">
          <div class="title1">
            <h1> {{card.title}} </h1>
          </div>
        </div>
        <div class="col-12">
          <div class="body1 px-1">
            <p> {{card.description}} </p>
          </div>
        </div>
        <div class="col-12 d-flex align-items-end justify-content-center">
          <router-link :to="'/ServicesDetails/' + card.id">
            <div class="read_more">{{$t('read_more')}}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="border_card"></div>
  </div>
</template>

<script>
import Constants from '../../../Plugins/Constants'
import $ from 'jquery'

export default {
  name: 'ServiceCard',
  data () {
    return {
      baseUrl: Constants.baseUrl
    }
  },
  props: {
    card: {
      title: String,
      description: String,
      icon: String,
      images: Array
    }
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#service_card .body1 p').css('direction', 'rtl')
    } else {
      $('#service_card .body1 p').css('direction', 'ltr')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/main";
#service_card .icon1 {
  //margin: 7vh 0 1.5vh 0;
  margin: 2rem 0 0.3rem 0;
}
#service_card .title1 {
  //margin-bottom: 2.5vh;
  margin-bottom: 1rem;
}
#service_card .icon1 img {
  //width: 4.9vmin;
  width: 32.9px;
  height: 32.99px;
  //height: 4.9vmin;
}
#service_card .title1 h1 {
  color: $definitionCardBlue;
  font-family: Messiri-Bold;
  font-size: 16.5px;
}
#service_card .body1 p {
  font-size: 14.52px;
  color: $definitionblack;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  //text-align: justify;
  height: 67px;
}
#service_card {
  filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
  -webkit-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
  -moz-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
  -o-filter:  drop-shadow(15px 15px 20px rgba(0, 0, 0, 0.2));
}
#service_card .main_card {
  clip-path: polygon(50% 0, 100% 20%, 100% 81%, 50% 100%, 0 81%, 0 20%);
  box-shadow: inset 5px 5px 10px rgba(255,255,255,0.25), inset -5px -5px 10px rgba(255,255,255,0.25);
  width: 237.78px;
  height: 247.01px;
  background: $lightGray;
  z-index: 100;
  position: absolute;
}
#service_card .border_card {
  width: 199.84px;
  height: 207.60px;
  bottom: -28.05px;
  z-index: 90;
  position: relative;
  background: $definitionblue;
  clip-path: polygon(50% 0, 100% 20%, 100% 81%, 50% 100%, 0 81%, 0 20%);
}
#service_card .read_more {
  color: $orangecarousel;
  display: inline-block;
  border-bottom: 1.5px solid;
  //position: absolute;
  //bottom: 5vh;
  //left:50%;
  //margin-left:-20.635px;
  line-height: 0.9;
  //text-align: center;
  //float: right;
  //margin-left: 23.27px;
  font-size: 11.88px;
}
@media screen  and (max-width: 999px) {
  //#service_card .main_card {
  //  width: 53.866vmin;
  //  height: 55.957vmin;
  //}
  //#service_card .border_card {
  //  width: 47.866vmin;
  //  height: 49.957vmin;
  //  bottom: -4.4vmin;
  //}
}
@media screen and (max-width: 768px) {
  //#service_card .icon1 img {
  //  width: 5.9vmin;
  //  height: 5.9vmin;
  //}
  //#service_card .title1 h1 {
  //  font-size: 3.5vmin;
  //}
  //#service_card .body1 p {
  //  font-size: 3vmin;
  //}
  //#service_card .icon1 {
  //  margin: 3.5vh 0 1.3vh 0;
  //}
  //#service_card .title1 {
  //  margin-bottom: 1.7vh;
  //}
  //#service_card .read_more {
  //  bottom: 5.5vh;
  //  font-size: 2.5vmin;
  //}
}
@media screen and (max-width: 999px) and (min-width: 768px) {
  //#service_card .icon1 img {
  //  width: 5.1vmin;
  //  height: 5.1vmin;
  //}
  //#service_card .title1 h1 {
  //  font-size: 2.7vmin;
  //}
  //#service_card .body1 p {
  //  font-size: 2.2vmin;
  //}
  //#service_card .icon1 {
  //  margin: 10vh 0 3vh 0;
  //}
  //#service_card .title1 {
  //  margin-bottom: 3vh;
  //}
  //#service_card .read_more {
  //  bottom: 5.5vh;
  //  font-size: 2vmin;
  //}
}
</style>
