<template>
  <div id="numbers" v-on:click="closeNav">
    <dama-by-numbers :cards="cards"/>
  </div>
</template>

<script>
import DamaByNumbers from '../components/Numbers/DamaByNumbers'
export default {
  name: 'Numbers',
  props: ['cards'],
  components: { DamaByNumbers },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    }
  },
  watch: {
    cards (val) {
      this.cards = val
    }
  }
}
</script>

<style scoped>
#numbers {
  /*scroll-margin-top: 20px;*/
  /*margin-top: -100px;*/
  /*padding-top: 100px;*/
  margin-top: -100px;
  padding-top: 100px;
}
</style>
