<template>
    <section id="definition">
        <div class="row flex-row-reverse  pe-5 ps-5 pt-4 pb-lg-4 pb-4 ">
          <div class="col-lg-4 mt-3 mb-md-3 mb-4 shadow_hex d-flex justify-content-center align-items-start">
            <div class="hexagon" :style="{ 'background': 'url( ' + baseUrl + image + ') no-repeat center center #E1E1E1'}" role="img" :aria-label="title"> </div>
<!--            <div class="hexagon"> </div>-->
          </div>
            <div class="col-lg-8 intro">
              <div class="row mt-lg-5 mt-3 mb-lg-5 mb-0">
                <div class="col-12 d-flex justify-content-start about pb-3">
                  <h1>{{$t('definition.about_us')}}</h1>
                </div>
<!--                <div class="col-12 d-flex justify-content-start pb-2 main">-->
<!--                  <p>{{$t('definition.main_title')}}</p>-->
<!--                </div>-->
                <div class="col-12 d-flex justify-content-start pb-3 second">
<!--                  <h4>{{$t('definition.second_title')}}</h4>-->
                  <h2>{{title}}</h2>
                </div>
                <div class="col-12 d-flex justify-content-start description">
<!--                  <p>{{$t('definition.description')}}</p>-->
                  <p>{{descriptionText}}&nbsp;&nbsp;<Span class="show-button" v-on:click="showLessMore" v-if="showButton && descriptionText!=''">{{showMore ? $t('read_less') : $t('read_more') }}</Span></p>
                </div>
<!--                <div class="col-12 d-flex justify-content-start mt-3 pb-lg-0 pb-4"> </div>-->
              </div>
            </div>
        </div>
        <VisionMissionValues :aboutInfo="aboutInfo"/>
    </section>
</template>

<script>
import VisionMissionValues from './VisionMissionValues'
import $ from 'jquery'
// import AboutusRepository from '../../Repositories/AboutusRepository'
import Constants from '../../Plugins/Constants'

export default {
  name: 'Definition',
  props: {
    aboutInfo: {
    }
  },
  components: { VisionMissionValues },
  data () {
    return {
      title: '',
      description: '',
      // image: '',
      // baseUrl: '',
      baseUrl: Constants.baseUrl,
      image: 'about_us.png',
      showMore: false,
      showButton: true,
      descriptionText: '',
      subText: ''
      // AboutusRepository: new AboutusRepository()
    }
  },
  methods: {
    get_about_us () {
      // const response = await this.AboutusRepository.get()
      this.title = this.aboutInfo.title
      this.description = this.aboutInfo.description
      this.image = this.aboutInfo.image
      // console.log('paragraph length', this.descriptionText.length)
      // if (this.description > 0) {
      // console.log('Helellellleel')
      // this.subText = this.description.substring(0, 473)
      // this.descriptionText = this.subText + ' .......'
      // }
      // console.log('hi', this.image)
    },
    showLessMore () {
      this.showMore = !this.showMore
      if (!this.showMore) {
        this.descriptionText = this.subText + ' .......'
      } else {
        this.descriptionText = this.description
      }
    }
  },
  created () {
    this.get_about_us()
  },
  mounted () {
    if (localStorage.getItem('lang') === 'ar') {
      $('#definition').css('direction', 'rtl')
      $('#definition .intro').css('padding-left', '6rem')
      $('#definition .main p').css('text-align', 'right')
      $('#definition .second h2').css('text-align', 'right')
    } else {
      $('#definition').css('direction', 'ltr')
      $('#definition .intro').css('padding-right', '6rem')
      $('#definition .main p').css('text-align', 'left')
      $('#definition .second h2').css('text-align', 'left')
    }
    // this.get
  },
  watch: {
    aboutInfo (val) {
      this.get_about_us()
      if (val.description.length < 474) {
        // console.log('lenggggg', val.description.length)
        this.showButton = false
        this.descriptionText = val.description
      } else {
        this.subText = this.description.substring(0, 473)
        this.descriptionText = this.subText + ' .......'
      }
    }
  }
}
</script>
<style scoped lang="scss">
    @import "src/scss/main";
    #definition {
        //background: rgba(225,225,225,1);
      background: url("../../assets/images/about_texture.png") no-repeat center center;
      background-size: cover;
      overflow: hidden;
    }
    #definition .shadow_hex {
      filter:  drop-shadow(5px 10px 20px rgba(0, 0, 0, 0.35));
      -webkit-filter: drop-shadow(5px 10px 20px rgba(0, 0, 0, 0.35));
      -moz-filter: drop-shadow(5px 10px 30px rgba(0, 0, 0, 0.35));
      -o-filter: drop-shadow(5px 10px 30px rgba(0, 0, 0, 0.35));
    }
    #definition .hexagon {
      clip-path: polygon(50% 0, 100% 20%, 100% 81%, 50% 100%, 0 81%, 0 20%);
      //width: 50.784vmin;
      width: 334.9px;
      //height: 52.718vmin;
      height: 347.9px;
      //background: url("../../assets/images/carousel2.png") no-repeat center center;
      //background-color: #1E1D23;
      background-size: cover!important;
    }
    #definition .about h1 {
        font-family: Messiri-Bold;
        color: $buttonorange;
        font-size: 29.7px!important;
        border-bottom: 1.5px solid;
       line-height: 0.9;
    }
    #definition .main p {
        font-family: Messiri-Bold;
        color: $definitionblue;
        font-size: 29.7px!important;
        //text-align: right!important;
    }
    #definition .second h2 {
        color: $buttonorange;
        font-size: 19.8px!important;
        border-bottom: 1.5px solid;
        line-height: 0.9;
    }
    #definition .description p {
        color: $definitionblack;
        font-size: 19.8px!important;
        text-align: justify!important;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 8; /* number of lines to show */
        //-webkit-box-orient: vertical;
    }
    //.sub-content {
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //  display: -webkit-box;
    //  -webkit-line-clamp: 8; /* number of lines to show */
    //  -webkit-box-orient: vertical;
    //}
    #definition .show-button {
      color: $buttonorange;
      font-family: Messiri-Bold;
      text-decoration: underline;
      font-size: 16.5px;
      text-underline-position: under!important;
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      //#definition .second h2, #definition .description {
      //  font-size: 4vmin!important;
      //}
      //#definition .about h1, #definition .main p {
      //  font-size: 4.5vmin!important;
      //}
    }
    @media screen  and (max-width: 992px) and (min-width: 768px) {
      #definition .second h2, #definition .description {
      //  font-size: 3.2vmin!important;
      //}
      //#definition .about h1, #definition .main p {
      //  font-size: 4.2vmin!important;
      }
    }@media screen and (max-width: 992px){
      #definition .intro {
        padding-right: 0!important;
        padding-left: 0!important;
      }
    }
    @media screen  and (min-width: 992px) {
      //#definition .hexagon {
      //  //width: 60.784vmin;
      //  //height: 62.718vmin;
      //  width: 61.866vmin;
      //  height: 63.957vmin;
      //}
      #definition .hexagon {
        margin-top: 4rem;
        margin-bottom: 4rem;
      }
    }
    @media screen and (max-width: 480px) {
      #definition .main p {
        font-size: 24.7px!important;
      }
      #definition .description p {
        font-size: 18.5px!important;
      }
    }
</style>
