import axios from 'axios'
import Constants from '../Plugins/Constants'

const baseDomain = Constants.baseUrl
const baseURL = `${baseDomain}` // Incase of /api/v1;

// ALL DEFUALT CONFIGURATION HERE

export default axios.create({
  baseURL,
  headers: {
    // "Authorization": "Bearer xxxxx"
    Accept: 'application/json',
    'Accept-Language': localStorage.getItem('lang')
  }
})
