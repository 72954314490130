import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Recruitment')
    // meta: {
    //   title: 'توظيف',
    //   metaTags: [
    //     {
    //       name: 'description',
    //       content: 'طلب توظيف في شركة داما للمجبول البيتوني'
    //     }
    //   ]
    // }
  },
  {
    path: '/ServicesDetails/:newsId',
    name: 'ServicesDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ServicesSinglePage')
    // meta: (route) => ({ title: route.params.newsTitle })
  },
  {
    path: '/NewsDetails/:newsId',
    name: 'NewsDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsSinglePage')
  }
  // {
  //   path: '/Details/:cardId',
  //   name: 'Details',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/CarouselSinglePage')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  // scrollBehavior (to, from, savedPosition) {
  //   return { savedPosition }
  // }
})

export default router
