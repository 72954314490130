<template>
    <div id="about_us" v-on:click="closeNav">
        <definition :aboutInfo="aboutInfo"/>
<!--        <vission-mission-values/>-->
    </div>
</template>

<script>
import Definition from '../components/AboutUs/Definition'
export default {
  name: 'AboutUs',
  props: {
    aboutInfo: {
    }
  },
  components: { Definition },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    }
  },
  watch: {
    aboutInfo (val) {
      this.aboutInfo = val
      // console.log('about info ', val)
    }
  }
}
</script>

<style scoped>
#about_us {
  margin-top: -100px;
  padding-top: 100px;
}
</style>
