<template>
  <section id="dama_news" class="slick_padding">
    <div class="pt-5 pb-4">
      <div class="">
        <h1 class="title mb-4"> {{$t('news.nav_title')}} </h1>
      </div>
      <div class="row">
        <div>
          <VueSlickCarousel v-bind="settings" :dots="true"  v-if="results.length>0" ref="carousel" :initialSlide="initSliderVal" @afterChange="changeVal($event)">
            <div class="pb-5" v-for="(card,i) in results" :key="i">
              <news-card  :card="card"/>
            </div>
            <template #customPaging="page">
              <div class="custom-dot">
                {{ page+1 }}
              </div>
            </template>
          </VueSlickCarousel>
          <a class="prev d-flex justify-content-center align-items-center" v-on:click="prev" v-if="results.length>0">
            <img src="../../assets/icons/left_white.svg">
          </a>
          <a class="next d-flex justify-content-center align-items-center" v-on:click="next" v-if="results.length>0">
            <img src="../../assets/icons/right_white.svg">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import NewsCard from './Components/NewsCard'
import NewsRepository from '../../Repositories/NewsRepository'
import Constants from '../../Plugins/Constants'

export default {
  name: 'DamaNews',
  components: { NewsCard, VueSlickCarousel },
  data () {
    return {
      markups: [],
      NewsRepo: new NewsRepository(),
      results: [],
      numPages: 0,
      page: 1,
      per_page: 6,
      // cards: [
      //   {
      //     cardTitle: this.$t('news.news[0].card_title'),
      //     cardDescription: this.$t('news.news[0].card_description'),
      //     cardBody: this.$t('news.news[0].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[1].card_title'),
      //     cardDescription: this.$t('news.news[1].card_description'),
      //     cardBody: this.$t('news.news[1].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[2].card_title'),
      //     cardDescription: this.$t('news.news[2].card_description'),
      //     cardBody: this.$t('news.news[2].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[3].card_title'),
      //     cardDescription: this.$t('news.news[3].card_description'),
      //     cardBody: this.$t('news.news[3].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[4].card_title'),
      //     cardDescription: this.$t('news.news[4].card_description'),
      //     cardBody: this.$t('news.news[4].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[5].card_title'),
      //     cardDescription: this.$t('news.news[5].card_description'),
      //     cardBody: this.$t('news.news[5].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[6].card_title'),
      //     cardDescription: this.$t('news.news[6].card_description'),
      //     cardBody: this.$t('news.news[6].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[7].card_title'),
      //     cardDescription: this.$t('news.news[7].card_description'),
      //     cardBody: this.$t('news.news[7].body')
      //   },
      //   {
      //     cardTitle: this.$t('news.news[8].card_title'),
      //     cardDescription: this.$t('news.news[8].card_description'),
      //     cardBody: this.$t('news.news[8].body')
      //   }
      // ],
      show: [],
      settings: {
        dots: true,
        arrows: false,
        focusOnSelect: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 2,
        responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1260,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  metaInfo () {
    return {
      // title: `${this.seoTitle}`,
      // meta: [
      //   {name: "robots", content: "index,follow"},
      //   {
      //     name: 'description',
      //     content: `${this.seoContent}`
      //
      //   }
      //
      // ],
      // link: [
      //   {rel: 'favicon', href: 'logo.ico'}
      // ],
      script: [{
        type: 'application/ld+json',
        // json: this.markups
        json: {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          '@graph': this.markups
        }
      }]
    }
  },
  methods: {
    async getPage () {
      // var currentWidth = window.innerWidth
      // if (currentWidth < 999 && currentWidth > 767) {
      //   this.per_page = 4
      // } else if (currentWidth < 767) {
      //   this.per_page = 2
      // }
      const x = await this.NewsRepo.getPage(this.page)
      this.results = x.content
      let i = 0
      for (i = 0; i < this.results.length; i++) {
        this.markups.push({
          '@type': 'ListItem',
          position: i + 1,
          name: this.results[i].title,
          item: Constants.siteUrl + 'NewsDetails/' + this.results[i].id
        })
      }
      console.log('markup', this.markups)
      // var i = 0
      // var item = []
      // item = x.content
      // for (i = 0; i < item.length; i++) {
      //   this.results.push(item[i])
      // }
      // this.numPages = x.lastPage
    },
    prev () {
      // if (this.page !== 1) {
      //   this.page -= 1
      //   this.getPage()
      //   this.$refs.carousel.prev()
      // }
      this.$refs.carousel.prev()
    },
    next () {
      // if (this.page < this.numPages) {
      //   this.page += 1
      //   this.getPage()
      //   this.$refs.carousel.next()
      // }
      this.$refs.carousel.next()
    },
    changeVal (slideIndex) {
      localStorage.removeItem('initCardSlider')
      localStorage.setItem('initCardSlider', Number(slideIndex))
      // return
      // }
    }
  },
  created () {
    // this.per_page = this.settings.slidesToShow
    // console.log('perpage', this.per_page)
    this.getPage()
    // console.log('innerWidth', window.innerWidth)
  },
  computed: {
    initSliderVal () {
      return Number(localStorage.getItem('initCardSlider'))
    }
  },
  watch: {

  }
}
</script>
<style lang="scss">
@import "src/scss/main";
#dama_news {
  overflow: hidden;
  background: url("../../assets/images/news_texture.png") no-repeat center center rgba(255,255,255,0.5);
  background-size: cover;
  position: relative;
  min-height: 295.02px!important;
}
#dama_news .title {
  font-family: Messiri-Bold;
  font-size: 27.06px;
  border-bottom: 1.5px solid;
  color: $buttonorange;
  display: inline-block;
  line-height: 0.9;
}
#dama_news .slick-next:before{
  content: url("../../assets/icons/right_service.svg");
}
#dama_news .slick-prev:before{
  content: url("../../assets/icons/left_service.svg");
}
#dama_news .slick-prev {
  left: -23px;
}
#dama_news .slick-next {
  right: -23px;
}
#dama_news .slick-prev,#dama_news .slick-next{
  height: unset !important;
  width: unset !important;
  z-index: 100000;
}
#dama_news .slick-list[data-v-3d1a4f76]{
  overflow: visible !important;
}
.slick_padding {
  padding-left: 1.5rem!important;
  padding-right: 1.5rem!important;
}
 #dama_news .slick-dots li{
    padding-top: 19px;
    padding-bottom: 19px;
    z-index: 350;
    //width: 50px;
  }
  #dama_news .slick-dots li button:before {
    font-size: 8px!important;
  }
  #dama_news .slick-dots {
    bottom: -9px!important;
  }
  #dama_news .slick-dots li.slick-active button:before {
  }
  .slick-active .custom-dot {
    color: $orangecarousel !important;
  }
  .custom-dot {
    color: rgba(46, 49, 146, 0.37)!important;
    font-size: 15px;
    font-family: Messiri-Bold;
  }
  .slick-active .custom-dot:hover {
    color: $orangecarousel!important;
  }
  .custom-dot:hover {
    color: #2E3192!important;
  }
  #dama_news .prev {
    top: 43%;
    margin-top: 18.5px;
    left: 0px;
    position: absolute;
    width: 43.56px;
    height: 81.84px;
    //background-color: rgba(255,255,255,0.4) !important;
    //backdrop-filter: blur(11px) opacity(1);
    //-webkit-backdrop-filter: blur(11px) opacity(1);
    //-moz-backdrop-filter: blur(11px) opacity(1);
    //-o-backdrop-filter: blur(11px) opacity(1);
    background-color: rgba(0,0,0,0.2) !important;
    backdrop-filter: blur(11px) opacity(1);
    -webkit-backdrop-filter: blur(11px) opacity(1);
    -moz-backdrop-filter: blur(11px) opacity(1);
    -o-backdrop-filter: blur(11px) opacity(1);
    cursor: pointer;
  }
  #dama_news .next {
    top: 43%;
    margin-top: 18.5px;
    right: 0;
    position: absolute;
    width: 43.56px;
    height: 81.84px;
    background-color: rgba(0,0,0,0.2) !important;
    backdrop-filter: blur(11px) opacity(1);
    -webkit-backdrop-filter: blur(11px) opacity(1);
    -moz-backdrop-filter: blur(11px) opacity(1);
    -o-backdrop-filter: blur(11px) opacity(1);
    cursor: pointer;
  }
  #dama_news .next img, #dama_news .prev img {
    width: 8.811px;
    height: 17.6px;
    //width: 13.35 vmin;
    //height: 26.7 vmin;
  }
@media screen and (max-width: 768px) {
  //#dama_news .title {
  //  font-size: 5.5vmin;
  //}
  //#dama_news .prev, #dama_news .next {
  //  width: 8.6vmin;
  //  height: 14.4vmin;
  //}
  //#dama_news .next img, #dama_news .prev img {
  //  width: 2.335vmin;
  //  height: 3.67vmin;
  //}
  #dama_news .slick-dots li{
    width: 23px;
  }
}
@media screen and (max-width: 999px) and (min-width: 768px) {
  //#dama_news .title {
  //  font-size: 4.7vmin;
  //}
  //#dama_news .next img, #dama_news .prev img {
  //  width: 2.335vmin;
  //  height: 3.67vmin;
  //}
  //#dama_news .prev, #dama_news .next {
  //  width: 7.6vmin;
  //  height: 13.4vmin;
  //}
}
@media screen and (min-width: 768px) {
  #dama_news .slick-dots li{
    width: 50px;
  }
}
</style>
