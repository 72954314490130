<template>
  <div class="home">
        <navbar/>
        <sidebar/>
        <intro :cards="cards" :contactInfo="contactInfo"/>
        <about-us :aboutInfo="aboutInfo"/>
        <services/>
        <clients/>
        <numbers :cards="cards"/>
        <news/>
<!--        <contact @contact="get_contacts($event)"/>-->
       <contact :contactInfo="contactInfo"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Intro from './Intro'
import Services from './Services'
import Navbar from '../components/Shared/Navbar'
import AboutUs from './AboutUs'
import Numbers from './Numbers'
import Contact from './Contact'
import Sidebar from '../components/Shared/Sidebar'
import News from './News'
import ContactsRepository from '../Repositories/ContactsRepository'
import StatisticsRepository from '../Repositories/StatisticsRepository'
import AboutusRepository from '../Repositories/AboutusRepository'
import Clients from './Clients'

export default {
  name: 'Home',
  components: {
    Clients,
    News,
    Sidebar,
    Contact,
    Numbers,
    AboutUs,
    Navbar,
    Services,
    Intro
  },
  data () {
    return {
      contactInfo: {},
      aboutInfo: {},
      cards: [
        {
          title: '',
          value: ''
        },
        {
          title: '',
          value: ''
        },
        {
          title: '',
          value: ''
        }
      ],
      ContactRepo: new ContactsRepository(),
      StatisticsRepo: new StatisticsRepository(),
      AboutUsRepo: new AboutusRepository()
    }
  },
  methods: {
    // closeNav () {
    //   document.getElementById('sidebar').style.width = '0'
    // }
    async get_statistics () {
      const response = await this.StatisticsRepo.get()
      this.cards = response.data.body
      // console.log('Fatima home', this.cards)
    },
    async get_contacts () {
      const response = await this.ContactRepo.get()
      // this.facebook = response.data.body.facebook
      // this.instagram = response.data.body.instagram
      // this.youtube = response.data.body.youtube
      // console.log('FATIMA', response)
      this.contactInfo = response.data.body
      // this.test = response.data.body.facebook
      // console.log('Home contacts', this.contactInfo)
    },
    async get_about_us () {
      const response = await this.AboutUsRepo.get()
      this.aboutInfo = response.data.body
      // console.log('home about', this.aboutInfo)
      // this.title = response.data.body.title
      // this.description = response.data.body.description
      // this.image = response.data.body.image
      // console.log('hi', this.image)
    }
  },
  created () {
    this.get_statistics()
    this.get_contacts()
    this.get_about_us()
    // console.log('Facenbbbbbbk', this.facebook)
  }
}
</script>
