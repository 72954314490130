<template>
  <section id="carousel">
    <div id="position_relative" class="position_relative">
      <div class="layer_one">
        <VueSlickCarousel  :arrows="false" :dots="true" :autoplay="false" ref="carousel"  @reInit="reInit" v-if="results.length>0" v-bind="settings" :initialSlide="initSliderVal" @afterChange="changeVal($event)">
          <div id="height_100" class="container-fluid height_100" v-for="(item, i) in results" :key="i" :style="{ 'background': 'url( ' + baseUrl + results[i].image + ') no-repeat center center'}">
            <div class="row one">
              <div id="carousel_card" class="col-lg-4 carousel_card d-flex align-items-center">
                <div class="row mb-5 pt-4 pb-4 background">
                  <div class="col-12 title">
                    <h1>{{results[i].title}}</h1>
                  </div>
                  <div class="col-12 description my-2">
                    <p>{{results[i].description}}</p>
                  </div>
                  <div class="col-12 read_more mt-3 mb-lg-0">
                    <!--                    <router-link :to="'/NewsDetails/' + results[i].id">-->
                    <button class="btn btn-primary" v-on:click="readMore()"> <a>{{$t('read_more')}}</a></button>
                    <!--                    </router-link>-->
                  </div>
                  <!--                  <div class="col-12">-->
                  <!--                    {{ i }}-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="col-lg-8"></div>
            </div>
          </div>
        </VueSlickCarousel>
        <a class="prev d-flex justify-content-center align-items-center" v-on:click="prev" v-if="results.length>0">
          <img src="../../assets/icons/left_white.svg">
        </a>
        <a class="next d-flex justify-content-center align-items-center" v-on:click="next" v-if="results.length>0">
          <img src="../../assets/icons/right_white.svg">
        </a>
      </div>
      <div id="layer_two" class="layer_two pointer">
        <div class="row h-100 two">
          <div class="col-lg-4"></div>
          <div class="col-lg-8 dama_counter d-flex justify-content-center align-items-end" v-if="results.length>0">
            <div class="dama_by_numbers pt-2 pb-2 ps-md-0 ps-2 pe-md-0 pe-2">
              <table class="">
                <tr class="number">
                  <td class="border_left pt-4" >{{statistic[0].value}}</td>
                  <td class="">{{statistic[1].value}}</td>
                  <td class="border_right">{{statistic[2].value}}</td>
                </tr>
                <tr class="description">
                  <td class="border_left"><p>{{statistic[0].title}}</p></td>
                  <td class=""><p>{{statistic[1].title}}</p></td>
                  <td class="border_right pb-2"><p>{{statistic[2].title}}</p></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="layer_three margin_bottom">
        <a class="d-md-inline-block d-block" :href="instagram" target="_blank">
          <img class="icon mb-md-0 mb-3 me-md-2 me-lg-2" src="../../assets/icons/instagram.svg" alt="Instagram icon">
        </a>
        <a class="d-md-inline-block d-block" :href="youtube" target="_blank">
          <img class="icon mb-md-0 mb-3 me-md-2 me-lg-2" src="../../assets/icons/youtube.svg" alt="Youtube icon">
        </a>
        <a class="d-md-inline-block d-block" :href="facebook" target="_blank">
          <img class="icon mb-md-0 mb-4 me-md-2 me-lg-2" src="../../assets/icons/facebook.svg" alt="Facebook icon">
        </a>
      </div>
      <div class="layer_four">
        <div class="tcontainer">
          <span>{{$t('news_bar.title')}}</span>
          <div class="ticker-wrap">
            <div id="ticker-move" class="ticker-move">
              <!--              <div class="ticker-item"><a href="#">{{$t('news_bar.description')}}</a></div>-->
              <!--              <div class="ticker-item"><a href="#">{{$t('news_bar.description')}}</a></div>-->
              <!--              <div class="ticker-item"><a href="#">{{$t('news_bar.description')}}</a></div>-->
              <!--              <div class="ticker-item"><a href="#">{{$t('news_bar.description')}}</a></div>-->
              <div class="ticker-item" v-for="(item, i) in news_results" :key="i">
                <router-link :to="'/NewsDetails/' + news_results[i].id">
                  {{news_results[i].description}}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import $ from 'jquery'
// import StatisticsRepository from '../../Repositories/StatisticsRepository'
import ContactsRepository from '../../Repositories/ContactsRepository'
import WebsiteSlidersRepository from '../../Repositories/WebsiteSlidersRepository'
import Constants from '../../Plugins/Constants'
// import NewsRepository from '../../Repositories/NewsRepository'
import NewsDescriptionsRepository from '../../Repositories/NewsDescriptionsRepository'

export default {
  name: 'MyComponent',
  components: { VueSlickCarousel },
  props: {
    contactInfo: {
    },
    cards: []
  },
  data () {
    return {
      facebook: '',
      instagram: '',
      youtube: '',
      results: [],
      numPages: 0,
      page: 1,
      // i: 0,
      per_page: 4,
      baseUrl: Constants.baseUrl,
      // StatisticsRepo: new StatisticsRepository(),
      ContactRepo: new ContactsRepository(),
      WebsiteSliderRepo: new WebsiteSlidersRepository(),
      NewsRepo: new NewsDescriptionsRepository(),
      statistic: [
        {
          title: '',
          value: ''
        },
        {
          title: '',
          value: ''
        },
        {
          title: '',
          value: ''
        }
      ],
      loaded: false,
      IsreInit: false,
      news_results: [],
      length1: 0,
      show: [],
      settings: {
        fade: true,
        speed: 500,
        autoPlay: true,
        autoplaySpeed: 6000,
        pauseOnHover: false
      }
    }
  },
  methods: {
    reInit () {
      if (!this.IsreInit) {
        this.initStyles()
        this.slickHeight()
        this.IsreInit = true
      }
    },
    initStyles () {
      document.getElementById('carousel').style.height = window.innerHeight + 'px'
      document.getElementById('position_relative').style.height = window.innerHeight + 'px'
      // // document.getElementById('height_100').style.height = window.innerHeight
      // document.getElementById('carousel_card').style.height = window.getInnerSlider + 'px'
      // console.log('carouselHieght', $('#carousel').css('height'))
      // console.log('windowInner', window.innerHeight)
      if (localStorage.getItem('lang') === 'ar') {
        $('#carousel .one, #carousel .two, #carousel .carousel_card').css('direction', 'rtl')
        $('#carousel .tcontainer span').css('right', '0')
        $('.ticker-wrap').css('padding-right', '100%')
        $('.ticker-move').css('animation-direction', 'reverse')
        // $('.ticker-item').css('direction', 'rtl')
        $('#carousel .slick-dots').css('left', '1vh')
        $('#carousel .layer_three').addClass('layer_right')
        $('#carousel .carousel_card').css('text-align', 'right')
        $('#carousel .background').addClass('ps-5')
        $('#carousel .background').addClass('pe-3')
        $('#carousel .layer_four a').css('direction', 'rtl')
        $('#carousel .prev').addClass('prev_ar')
        $('#carousel .next').addClass('next_ar')
      } else {
        $('#carousel .one, #carousel .two, #carousel .carousel_card').css('direction', 'ltr')
        $('#carousel .tcontainer span').css('left', '0')
        $('.ticker-wrap').css('padding-left', '100%')
        // $('.ticker-item').css('direction', 'ltr')
        $('#carousel .slick-dots').css('right', '1vh')
        $('#carousel .layer_three').addClass('layer_left')
        $('#carousel .carousel_card').css('text-align', 'left')
        $('#carousel .background').addClass('pe-5')
        $('#carousel .background').addClass('ps-3')
        $('#carousel .layer_four a').css('direction', 'ltr')
        $('#carousel .prev').addClass('prev_en')
        $('#carousel .next').addClass('next_en')
      }
      // $('#carousel .slick-dots').css('bottom', '50%').css('bottom', '-=76px')
    },
    async get_statistics () {
      // const response = await this.StatisticsRepo.get()
      // this.cards = response.data.body
    },
    // async get_contacts () {
    //   const response = await this.ContactRepo.get()
    //   this.facebook = response.data.body.facebook
    //   this.instagram = response.data.body.instagram
    //   this.youtube = response.data.body.youtube
    // },
    get_contacts () {
      this.facebook = this.contactInfo.facebook
      this.instagram = this.contactInfo.instagram
      this.youtube = this.contactInfo.youtube
    },
    async get_website_sliders (slideIndex) {
      // console.log('our carousel is ready', slideIndex)
      const x = await this.WebsiteSliderRepo.getPage(this.page)
      // console.log('our carousel is ready', x)
      this.results = x.content
      // this.page ===+ 1
      // console.log('helllllo', this.results[0].type_id)
      // this.initStyles()
    },
    async getNewsTrick () {
      // console.log('our carousel is ready', slideIndex)
      const x = await this.NewsRepo.get()
      // console.log('our carousel is ready', x)
      this.news_results = x.data.body
      // this.page ===+ 1
      // console.log('hi', this.news_results)
      this.lengthOfItems()
      document.getElementById('ticker-move').style.animationDuration = this.length1 * 0.08 + 's'
      // document.getElementsByName('slick-dots').
    },
    next () {
      this.$refs.carousel.next()
    },
    prev () {
      this.$refs.carousel.prev()
    },
    lengthOfItems () {
      // var i=0;
      var i = 0
      var item = []
      item = this.news_results
      for (i = 0; i < item.length; i++) {
        this.length1 += item[i].description.length
      }
    },
    readMore () {
      // console.log('iiiii', i)
      var sliderIndex = Number(localStorage.getItem('initCardSlider'))
      if (this.results[sliderIndex].type === 2) {
        this.$router.push('/NewsDetails/' + this.results[sliderIndex].type_id)
      } else {
        this.$router.push('/ServicesDetails/' + this.results[sliderIndex].type_id)
      }
      // console.log(Number(localStorage.getItem('initCardSlider')))
    },
    slickHeight () {
      var mySlickHeight = $('#carousel .slick-dots').height()
      var calcSlick = mySlickHeight / 2
      var currentHeight = 50 * window.innerHeight / 100
      // console.log('currentHeight', currentHeight)
      // console.log('calcSlick', calcSlick)
      $('#carousel .slick-dots').css({
        bottom: currentHeight + 'px',
        height: calcSlick + 'px'
      })
      // this.reInit()
      // console.log('heightttttttt')
    },
    changeVal (slideIndex) {
      localStorage.removeItem('initCardSlider')
      localStorage.setItem('initCardSlider', Number(slideIndex))
      // return
      // }
    }
  },
  created () {
    this.get_website_sliders()
    // this.get_statistics()
    this.get_contacts()
    this.getNewsTrick()
    window.addEventListener('resize', this.slickHeight)
    window.addEventListener('resize', this.initStyles)
    // console.log('cards Carousel', this.cards)
    // localStorage.setItem('initCardSlider', 0)
  },
  destroyed () {
    window.addEventListener('resize', this.slickHeight)
    window.removeEventListener('resize', this.initStyles)
  },
  mounted () {
    // this.get_website_sliders()
    // this.get_statistics()
    // this.get_contacts()
    this.initStyles()
    this.slickHeight()
    // this.slickHeight()
    // this.$refs.carousel.prev()
  },
  computed: {
    initSliderVal () {
      return Number(localStorage.getItem('initCardSlider'))
    }
  },
  watch: {
    contactInfo (val) {
      this.get_contacts()
    },
    cards (val) {
      this.statistic = val
    }
  }
}
</script>
<style lang="scss">
@import "src/scss/main";
#carousel {
  overflow: hidden;
  position: relative;
  text-underline-position: under!important;
  height: 100vh;
}
#carousel .pointer {
  pointer-events: none;
}
#carousel .position_relative {
  position: relative;
  //height: 100vh;
}
#carousel .height_100 {
  height: 100%;
  //height: 100vh;
  background-size: cover!important;
}
#carousel .fixed-background {
  //background-color: rgba(0,0,0,0.5);
  //backdrop-filter: blur(5px) opacity(1);
  //-webkit-backdrop-filter: blur(5px) opacity(1);
  //z-index: 100;
  //height: 100vh;
  //width: 33%;
  //position: absolute;
  //top: 0;
}
#carousel .layer_one {
  z-index: 200;
}
#carousel .layer_two {
  z-index: 300;
  position: absolute;
  bottom: 6.5vh;
  left: 0vh;
  width: 100%;
  height: 100vh;
}
#carousel .layer_two .description {
  font-size: 13.2px!important;
}
#carousel .layer_two td {
  //width: 22vmin;
  width: 151.8px;
  vertical-align: text-top;
  text-align: center;
}
#carousel .layer_three {
  z-index: 300;
  position: absolute;
  bottom: 10.5vh;
  //right: 0;
}
#carousel .dama_counter .dama_by_numbers {
  direction: rtl;
  background-color: rgba(0,0,0,0.5) !important;
  backdrop-filter: blur(10px) opacity(1);
  -webkit-backdrop-filter: blur(10px) opacity(1);
}
#carousel .number {
  font-family: Messiri-Bold;
  font-size: 43.56px;
}
#carousel .border_right {
  border-right: 1px solid $primarywhite;
}
#carousel .border_left {
  border-left: 1px solid $primarywhite;
}
#carousel .carousel_card {
  //direction: rtl;
  //text-align: right;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px) opacity(1);
  -webkit-backdrop-filter: blur(5px) opacity(1);
  //height: 100%;
  height: 100vh;
}
#carousel .carousel_card .title h1{
  font-size: 30px!important;
  font-family: Messiri-Bold;
  color: $orangecarousel;
  //height: 87.78px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
#carousel .carousel_card .description {
  font-size: 13.2px;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
#carousel .read_more button {
  font-family: Messiri-Regular;
  font-size: 11.88px;
  background: $buttonorange;
  border-radius: 0;
  border: 1px solid $borderorange;
  text-decoration: underline;
  //padding: 0.8vmin 2.8vmin;
  width: 92.4px;
  height: 33px;
}
#carousel .icon {
  margin: 0 14px;
  width: 23.1px;
}
//#carousel .slide0, #carousel .slide1,
//#carousel .slide2 {
//  width: 100%;
//  height: 100vh;
//}
//#carousel .slide0{
//  background: url("../../assets/images/carousel2.png") no-repeat center center;
//  background-size: cover;
//}
//#carousel .slide1{
//  background: url("../../assets/images/carousel1.png") no-repeat center center;
//  background-size: cover;
//}
//#carousel .slide2{
//  background: url("../../assets/images/carousel3.png") no-repeat center center;
//  background-size: cover;
//}
#carousel .slick-next:before{
  content: url("../../assets/icons/right_arrow.svg");
  position: absolute;
  bottom: -48vh;
  right: 23vw;
  -webkit-transition: transform .2s ease-in-out;
}
#carousel .slick-prev:before{
  content: url("../../assets/icons/left_arrow.svg");
  position: absolute;
  bottom: -48vh;
  left: 69vw;
  -webkit-transition: transform .2s ease-in-out;
}
#carousel .slick-next:hover:before , #carousel .slick-prev:hover:before{
  transform: scale(1.1);
  -webkit-transition: transform .2s ease-in-out;
}
#carousel .slick-prev, #carousel .slick-next{
  z-index: 350;
}
#carousel .slick-dots {
  //bottom: 50%;
  width: fit-content;
  vertical-align: middle;
}
#carousel .slick-dots li{
  display: block;
  padding-top: 19px;
  padding-bottom: 19px;
  color: #707070;
  z-index: 350;
}
#carousel .slick-dots li button:before {
  font-size: 8px;
  color: #FFF;
}
.layer_right {
  right: 1.5rem;
}
.layer_left {
  left: 1.5rem;
}
#carousel .next, #carousel .prev {
  display: none;
  background-color: rgba(0,0,0,0.2) !important;
  backdrop-filter: blur(11px) opacity(1);
  -webkit-backdrop-filter: blur(11px) opacity(1);
  -moz-backdrop-filter: blur(11px) opacity(1);
  -o-backdrop-filter: blur(11px) opacity(1);
  cursor: pointer;
  position: absolute;
  width: 52.8px;
  height: 52.8px;
}
#carousel .layer_three .prev, #carousel .layer_three .next {
  width: 8vmin;
  height: 8vmin;
  background: red;
}
.prev_ar {
  bottom: 6.5vh;
  //right: calc(33.33333333% - 8vmin);
}
.next_ar {
  bottom: 6.5vh;
  //right: calc(33.33333333% - 16vmin);
}
.prev_en {
  bottom: 6.5vh;
  //left: calc(33.33333333% - 16vmin);
}
.next_en {
  bottom: 6.5vh;
  //left: calc(33.33333333% - 8vmin);
}
#carousel .next img, #carousel .prev img {
  width: 7.7px;
  height: 15.5px;
}
@media screen and (max-width: 480px) {

  #carousel .layer_two .description {
    font-size: 10px!important;
  }
  #carousel .layer_two td {
    width: 90.33px
  }
  #carousel .number {
    font-size: 25px;
  }
  #carousel .carousel_card .title h1{
    font-size: 20px!important;
  }
  #carousel .carousel_card .description {
    font-size: 12px;
  }
  #carousel .read_more button {
    font-size: 12px;
  }
  #carousel .icon {
    margin: 0 10px;
    width: 20px;
  }
  #carousel .layer_three {
    bottom: 5.5vh!important;
  }
  #carousel .background {
    width: calc((100% - 260.99px)/2 + 286.98px) !important;
  }
}
@media screen and (max-width: 768px) {
  //#carousel .ms-5 {
  //  margin-left: 3.2rem !important;
  //}
  //#carousel .me-5 {
  //  margin-right: 3.2rem !important;
  //}
  //#carousel .slide0, #carousel .slide1,
  //#carousel .slide2 {
  //  height: 94vh;
  //}
  #carousel .carousel_card .title h1 {
    //font-size: 5vmin;
  }
  //#carousel .carousel_card .description {
  //  font-size: 2.2vmin;
  //}
  //#carousel .carousel_card .read_more a{
  //  font-size: 3vmin;
  //}
  //#carousel .layer_two .description {
  //  font-size: 2.5vmin !important;
  //}
  //#carousel .layer_two .number{
  //  font-size: 6vmin !important;
  //}
  #carousel .layer_three {
    bottom: 4.5vh;
  }
  //.tcontainer {
  //  font-size: 10.56px;
  //}
  //.tcontainer span {
  //  width: 7.4vh!important;
  //  font-size: 10.56px;
  //}
  .layer_right {
    right: 0.2rem;
  }
  .layer_left {
    left: 0.2rem;
  }
}
@media screen and (min-width: 992px) {
  .prev_ar {
    right: calc(33.33333333% - 52.8px);
  }
  .next_ar {
    right: calc(33.33333333% - 105.6px);
  }
  .prev_en {
    left: calc(33.33333333% - 105.6px);
  }
  .next_en {
    left: calc(33.33333333% - 52.8px);
  }
}
@media screen  and (max-width: 992px) and (min-width: 768px){
  //#carousel .slide0, #carousel .slide1,
  //#carousel .slide2 {
  //  height: 84vh;
  //}
  //#carousel .carousel_card .read_more a{
  //  font-size: 2.3vmin;
  //}
  //#carousel .layer_two .description {
  //  font-size: 2vmin !important;
  //}
  //.tcontainer span {
  //  width: 8.6vh!important;
  //}

  .prev_ar {
    left: 0!important;
  }
  .next_ar {
    left: 52.8px!important;
  }
  .prev_en {
    right: 52.8px!important;
  }
  .next_en {
    right: 0vmin!important;
  }
  #carousel .carousel_card .title h1{
    font-size: 30px!important;
  }
  #carousel .background {
    width: calc((100% - 432.4px)/2 + 455.4px) !important;
  }
}
@media screen  and (max-width: 768px) and (min-width: 480px) {
  #carousel .carousel_card .title h1{
    font-size: 20px!important;
  }
  #carousel .background {
    width: calc((100% - 320.99px)/2 + 360.99px) !important;
  }
  #carousel .layer_two .description {
    font-size: 12px!important;
  }
  #carousel .layer_two td {
    width: 120.33px
  }
  #carousel .number {
    font-size: 33px;
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  //#carousel .ms-5 {
  //  margin-left: 10.9rem !important;
  //}
  //#carousel .me-5 {
  //  margin-right: 10.9rem !important;
  //}
  //#carousel .carousel_card .description {
  //  font-size: 2.2vmin;
  //}
  //.tcontainer {
  //  font-size: 2vmin!important;
  //}
  #carousel .background {
    //width: 77%;
  }
  //#carousel .next img, #carousel .prev img {
  //  width: 2.335vmin;
  //  height: 3.67vmin;
  //}
  //#carousel .prev, #carousel .next {
  //  width: 7.6vmin;
  //  height: 13.4vmin;
  //}
}
@media screen and (max-width: 768px) {
  //#carousel .carousel_card .title h1 {
  //  font-size: 6vmin;
  //}
  //#carousel .carousel_card .description {
  //  font-size: 3vmin;
  //}
  #carousel .background {
    //width: 94%;
  }
  #carousel .prev_ar, #carousel .next_ar, #carousel .prev_en, #carousel .next_en {
    display: none!important;
  }
}
@media screen and (max-width: 992px){
  #carousel .background {
    background-color: rgba(0,0,0,0.5) !important;
    backdrop-filter: blur(5px) opacity(1);
    -webkit-backdrop-filter: blur(5px) opacity(1);
    //min-height: 50vmin;
    //width: 90%;
    //height: 30%;
  }
  #carousel .carousel_card {
    background: none!important;
    backdrop-filter: none!important;
    -webkit-backdrop-filter: none!important;
  }
}
#carousel .layer_four {
  z-index: 500;
  position: absolute;
  bottom: 0vh;
  right: 0;
  width: 100%;
}
#carousel .layer_four a {
  text-decoration: none!important;
}
.tcontainer {
  width: 100%;
  overflow: hidden;
  height: 6.5vh;
  background: $definitionblack;
  font-size: 10.56px;
  font-family: Messiri-Regular;
}
.tcontainer span {
  position: absolute;
  color: $definitionblue;
  background: $buttonorange;
  z-index: 600;
  height: 6.5vh;
  width: 9.6vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ticker-wrap {
  width: 100%;
  height: 100%;
  background: none;
}
@keyframes ticker {
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(-100%, 0, 0); }
}
.ticker-move {
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  //animation-duration: 130s;
  //animation-direction: reverse;
  height: 100%;
}
.ticker-move:hover {
  animation-play-state: paused;
}
.ticker-item {
  padding: 0 40px;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
@media screen and (min-width: 992px) {
  #carousel .next, #carousel .prev {
    display: block;
  }
}
</style>
