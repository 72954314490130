<template>
  <div id="news" v-on:click="closeNav">
    <dama-news/>
  </div>
</template>

<script>

import DamaNews from '../components/News/DamaNews'
export default {
  name: 'news',
  components: { DamaNews },
  methods: {
    closeNav () {
      document.getElementById('sidebar').style.width = '0'
    }
  }
}
</script>

<style scoped>
#news {
  margin-top: -100px;
  padding-top: 100px;
}
</style>
