import CrudRepository from './CrudRepository'
import AxiosClient from './AxiosClient'

export default class ClientRepository extends CrudRepository {
  constructor () {
    super('clients')
  }

  async getPage (pageNumber = 0, pageSize = 10) {
    if (pageSize === undefined || pageSize === null) {
      pageSize = null
    }
    // console.log('test', pageSize)
    const requestParams = {
      page: pageNumber,
      pagination: 1,
      page_size: pageSize
    }
    // console.log('requestParams', requestParams)
    try {
      const { data } = await AxiosClient.get(this.resource, {
        params: requestParams
      })
      const requestData = data
      // console.log('data', data)
      // console.log('Dataaaaaaaaaaaaaaa', requestData.body)
      // console.log('rees', requestData.body.results)
      return {
        content: requestData.body.results,
        lastPage: requestData.body.num_pages,
        perPage: requestData.body.per_page
      }
    } catch (e) {
      // console.log(e)
      return {
        content: [],
        lastPage: null,
        perPage: null
      }
    }
  }

  async getSigleClientById (id) {
    return await AxiosClient.get(`${this.resource}/${id}`)
  }
}
